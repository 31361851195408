import { Button, Link, Table, TableBody, TableCell, TableHead, TableRow, useAuthenticator } from '@aws-amplify/ui-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Modal, Toast, ToastContainer } from 'react-bootstrap';
import SiteSpinner from '../../components/UI/Spinner';
import ProtectedArea from '../../hoc/protected-area';
import { BookingData } from '../../interfaces';
import { getBookingByIdApi } from '../../services/'
import { getRepeatDuration } from '../../store/utility'
import moment from 'moment';
import { Auth } from 'aws-amplify';
const BookingItems = (props: any) => {
    const [adminNavLinks, setAdminNavLinks] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = useState(true)
    const [show, setShow] = useState(false);
    const [data, setData] = useState<BookingData[]>([])
    function HandlerView(value: any) {
        fetchAllBookings(value);
        setShow(true);
    }
    async function fetchAllBookings(value: string) {
        const response = await getBookingByIdApi(value);
        setData([response]);
        setIsLoading(false);
    };
    const handleClose = () => {
        setShow(false);
        setData([]);
    }
    React.useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(currentUser => {
                let role: string = currentUser.getSignInUserSession()?.getIdToken().payload["cognito:groups"][0]
                setAdminNavLinks(role === 'Admin');
            })
            .catch(() => {
                console.log("Not signed in");
            });

        return () => {
            console.log('This will be logged on unmount');
        };
    }, [])
    return (
        <>
            <Table
                className="all-booking-table table-responsive ps-sm-0"
                caption=""
                highlightOnHover={false}>
                <TableHead>
                    <TableRow>
                        <TableCell as="th">Booking Date/Time</TableCell>
                        <TableCell as="th">Booking ID</TableCell>
                        {adminNavLinks ? <>
                            <TableCell as="th">Name</TableCell>
                            <TableCell as="th">Email/Phone</TableCell>
                        </> : null}
                        <TableCell as="th">Booking Status</TableCell>
                        <TableCell as="th">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.bookings.map((o: BookingData, i: number) => <TableRow key={i}>
                        <TableCell className='d-none-md width-50-sm font-weight-bold'>Booking Date/Time</TableCell>
                        <TableCell>{moment(o.bookingDate).format('MMM. DD, YYYY')} {o.bookingStartTime}-{o.bookingEndTime}</TableCell>
                        <TableCell className='d-none-md width-50-sm font-weight-bold'>Booking ID</TableCell>
                        <TableCell>{o.bookingID}</TableCell>
                        {adminNavLinks ? <>
                            <TableCell className='d-none-md width-50-sm font-weight-bold'>Name</TableCell>
                        <TableCell>{o.ownerDetail?.name}</TableCell>
                        <TableCell className='d-none-md width-50-sm font-weight-bold'>Email/Phone</TableCell>
                            <TableCell>{_.get(o.ownerDetail, 'email') ? o.ownerDetail?.email : o.guestEmail}/{_.get(o.ownerDetail, 'phone') ? o.ownerDetail?.phone : o.guestPhone}</TableCell>
                        </> : null}
                        <TableCell className='d-none-md width-50-sm font-weight-bold'>Booking Status</TableCell>
                        <TableCell>{o.bookingStatus}</TableCell>
                        <TableCell>
                            <Button
                                fontWeight="normal"
                                size="small"
                                variation="link"
                                onClick={() => props.history.push(`/reschedule/${o.id}`)}>Reschedule/Edit</Button>
                            <Button
                                fontWeight="normal"
                                size="small"
                                variation="link"
                                onClick={() => props.cancelBookings(o.id, "Cancel", o.parentSeriesId)}>Cancel</Button>
                            {adminNavLinks ? <>
                            <Button
                                fontWeight="normal"
                                size="small"
                                variation="link"
                                onClick={() => props.deleteBooking(o.id)}>Delete</Button>
                                </> : null}
                            <Button
                                fontWeight="normal"
                                size="small"
                                variation="link"
                                onClick={() => HandlerView(o.id)}>View</Button>
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Booking Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!_.isEmpty(data) ? data.map((o: BookingData, i: number) =>
                        <div className='row' key={i}>
                            {adminNavLinks ? <>
                                <div className='col-md-6'>Name</div>
                                <div className='col-md-6'>{_.get(o.ownerDetail, 'name') ? o.ownerDetail?.name : null}</div>
                                <div className='col-md-6'>Email</div>
                                <div className='col-md-6'>{_.get(o.ownerDetail, 'email') ? o.ownerDetail?.email : o.guestEmail}</div>
                                <div className='col-md-6'>Phone</div>
                                <div className='col-md-6'>{_.get(o.ownerDetail, 'phone') ? o.ownerDetail?.phone : o.guestPhone}</div>
                                <div className='col-md-6'>Street</div>
                                <div className='col-md-6'>{_.get(o.ownerDetail, 'address') ? o.ownerDetail?.address : o.guestAddress}</div>
                                <div className='col-md-6'>City</div>
                                <div className='col-md-6'>{_.get(o.ownerDetail, 'city') ? o.ownerDetail?.city : o.guestCity}</div>
                            </> : null}
                            <div className='col-md-6'>Booking ID</div>
                            <div className='col-md-6'>{o.bookingID} </div>
                            <div className='col-md-6'>Booking Date</div>
                            <div className='col-md-6'>{moment(o.bookingDate).format('MMM. DD, YYYY')} </div>
                            <div className='col-md-6'>Booking Time</div>
                            <div className='col-md-6'>{o.bookingStartTime} - {o.bookingEndTime}</div>
                            <div className='col-md-6'>Service Type</div>
                            <div className='col-md-6'>{o.serviceType == "dog-walking" ? "Dog Walking" : "House Visit"}</div>
                            <div className='col-md-6'>Booking Status</div>
                            <div className='col-md-6'>{o.bookingStatus}</div>
                            {o.repeatDuration != "" &&
                                <>
                                    <div className='col-md-6'>Repeat Duration</div>
                                    <div className='col-md-6'>{getRepeatDuration(o.repeatDuration)}</div></>
                            }
                            <div className='col-md-6'>Booking Amount</div>
                            <div className='col-md-6'> ${o.bookingAmount}</div>
                            <div className='col-md-12 mt-2'>
                                <Table
                                    caption=""
                                    highlightOnHover={true}
                                    size={"small"}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell as="th">Pet Name</TableCell>
                                            <TableCell as="th">Pet Type</TableCell>
                                            <TableCell as="th">Pet Needs</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {o.pets?.map(data =>
                                            <>
                                                <TableRow>
                                                    <TableCell>{data.petName}</TableCell>
                                                    <TableCell>{data.petType}</TableCell>
                                                    <TableCell>{data.petNeeds}</TableCell>
                                                </TableRow>

                                            </>
                                        )}
                                    </TableBody>
                                </Table></div>
                        </div>
                    ) : <SiteSpinner />}
                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn btn-black' onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )

};
export default BookingItems