import React, { useEffect, useState } from 'react';
import "animate.css/animate.min.css";
import { Formik, Field, FieldArray, ErrorMessage, Form as FormFormik } from "formik";
import ScrollAnimation from 'react-animate-on-scroll';
import * as Yup from "yup";
import { Form, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { createContactQueryApi, sendContactEmailApi, sendSampleEmailApi } from '../../services';
import { Button } from '@aws-amplify/ui-react';
import { renderEmail } from 'react-html-email'
import PhoneNumberMask from '../../components/UI/Elements/masked-phone';
import ContactEmail from '../Emails/contact-email';
import ConfirmationToast from '../../components/UI/ConfirmationToast';
import { RiLoader5Line } from "react-icons/ri";
const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .required("name is required"),
    email: Yup.string()
        .required("email time is required"),
    phone: Yup.string()
        .required("phone is required"),
    subject: Yup.string()
        .required("subject is required"),
    message: Yup.string()
        .required("message is required"),
});
const Contact = () => {
    const [show, setShow] = useState(false);
    const [toastData, setToastData] = useState<any>({
        title: '',
        desciption: '',
        type: '',
        showToast: false
    })
    const toggleConfirmation = () => setToastData({ ...toastData, showToast: !toastData.showToast });
    return (
        <div>
            <section className="contactme-banner">
                <div className="container">
                    <div className="contactme-banner-titile text-center">
                        <h2> <span className="text-theme1"></span> CONTACT <span className="text-dark">ME</span> </h2>
                    </div>
                </div>
            </section>
            <section className="contactme-form-wrap">
                <div className="container">
                    <div className="row align-items-center justify-content-around">
                        <ScrollAnimation className="col-xl-5 col-md-5" animateIn="fadeIn">
                            <div className="contact-list">
                                <ul className="list-unstyled contact-list">
                                    {/* <li className="mb-4">
                                         <a className="text-decortaion-none " href="tel:4157179015">(415) 717-9015</a> 
                                    </li> */}
                                    <li className="">
                                        <a className="text-decortaion-none " href="mailto:lexi@lexination.com">lexi@lexination.com</a>
                                    </li>
                                </ul>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation className="col-xl-5 col-md-7" animateIn="fadeIn" data-animate="fadeIn" data-duration="3.0s" data-delay="0.5s">
                            <Formik
                                initialValues={{
                                    name: "",
                                    email: "",
                                    phone: "",
                                    subject: "",
                                    message: ""
                                }}
                                validationSchema={ContactSchema}
                                onSubmit={(values, { resetForm }) => {
                                    setShow(true);
                                    const messageHtml = renderEmail(
                                        <ContactEmail subject={values.subject} name={values.name} mobileNumber={values.phone} emailAddress={values.email} message={values.message} />
                                    );
                                    createContactQueryApi(values).then(o => {
                                        sendContactEmailApi(messageHtml, values.subject)
                                        setToastData({
                                            title: 'Confirmation',
                                            description: "I'll be in touch with you ASAP!",
                                            type: 'success',
                                            showToast: true
                                        });
                                        setShow(false);
                                        resetForm()
                                    }).catch(e => {
                                        setToastData({
                                            title: 'Error',
                                            description: 'Something went wrong! Please try again..',
                                            type: 'danger',
                                            showToast: true
                                        });
                                        resetForm()
                                    })
                                }}
                            >
                                {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                                    <FormFormik>
                                        <div className="contactme-form">
                                            <h2 className="text-center"> <span className="text-theme1">CONTACT</span> <span className="text-dark">ME</span></h2>
                                            <div className="row">
                                                <div className="col mb-10">
                                                    <Field
                                                        name={`name`}
                                                    >{({ field, formProps }: any) => (
                                                        <Form.Group controlId={`name`} className="mb-3">
                                                            <div className="input-group contactme-form-name">
                                                                <span className="input-group-text"></span>
                                                                <Form.Control type={'text'} placeholder="Name" value={field.value} onChange={field.onChange} />

                                                            </div>
                                                            <ErrorMessage name={`name`} component="div" className={"error"} />
                                                        </Form.Group>
                                                    )}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mb-10">
                                                    <Field
                                                        name={`email`}
                                                    >{({ field, formProps }: any) => (
                                                        <Form.Group controlId={`email`} className="mb-3">
                                                            <div className="input-group contactme-form-email">
                                                                <span className="input-group-text"></span>
                                                                <Form.Control type={'text'} placeholder="Email" value={field.value} onChange={field.onChange} />

                                                            </div>
                                                            <ErrorMessage name={`email`} component="div" className={"error"} />
                                                        </Form.Group>
                                                    )}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mb-10">
                                                    <Field
                                                        name={`phone`}
                                                    >{({ field, formProps }: any) => (
                                                        <Form.Group controlId={`phone`} className="mb-3">
                                                            <div className="input-group contactme-form-phone">
                                                                <span className="input-group-text"></span>
                                                                {/* <Form.Control type={'text'} placeholder="Phone" value={field.value} onChange={field.onChange} /> */}
                                                                <PhoneNumberMask
                                                                    id="phone"
                                                                    name="phone"
                                                                    type="tel"
                                                                    placeholder="Phone (required)"
                                                                    field={field}
                                                                    setFieldValue={setFieldValue}
                                                                />

                                                            </div>
                                                            <ErrorMessage name={`phone`} component="div" className={"error"} />
                                                        </Form.Group>
                                                    )}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mb-10">
                                                    <Field
                                                        name={`subject`}
                                                    >{({ field, formProps }: any) => (
                                                        <Form.Group controlId={`subject`} className="mb-3">
                                                            <div className="input-group contactme-form-subject">
                                                                <span className="input-group-text"></span>
                                                                <Form.Control type={'text'} placeholder="Subject" value={field.value} onChange={field.onChange} />
                                                            </div>
                                                            <ErrorMessage name={`subject`} component="div" className={"error"} />
                                                        </Form.Group>
                                                    )}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mb-10">
                                                    <Field
                                                        name={`message`}
                                                    >{({ field, formProps }: any) => (
                                                        <Form.Group controlId={`message`} className="mb-3">
                                                            <div className="input-group contactme-form-message">
                                                                {/* <span className="input-group-text"></span> */}
                                                                <Form.Control as="textarea" rows={3} placeholder="Message" value={field.value} onChange={field.onChange} />
                                                            </div>
                                                            <ErrorMessage name={`message`} component="div" className={"error"} />
                                                        </Form.Group>
                                                    )}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button className={show?"btn btn-light":"bg-theme1 text-white book-date-btn btn--1"} disabled={show?true:false} >
                                                    {show ?<RiLoader5Line className='mr-2' style={{color:'#92cff7'}}/>: null }
                                                    {show?<span>Sending...</span>:<span>Send Message</span>}
                                                   </button>
                                            </div>
                                        </div>
                                    </FormFormik>
                                )}
                            </Formik>
                        </ScrollAnimation>
                    </div>
                </div>
            </section>
            <ConfirmationToast
                title={toastData.title}
                description={toastData.description}
                type={toastData.type}
                showToast={toastData.showToast}
                toggleConfirmation={toggleConfirmation}
            />

        </div>
    );

}

export default Contact;

