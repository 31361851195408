/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBookingDraft = /* GraphQL */ `
  mutation CreateBookingDraft(
    $input: CreateBookingDraftInput!
    $condition: ModelBookingDraftConditionInput
  ) {
    createBookingDraft(input: $input, condition: $condition) {
      id
      bookingID
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      repeat
      guestEmail
      guestPhone
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBookingDraft = /* GraphQL */ `
  mutation UpdateBookingDraft(
    $input: UpdateBookingDraftInput!
    $condition: ModelBookingDraftConditionInput
  ) {
    updateBookingDraft(input: $input, condition: $condition) {
      id
      bookingID
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      repeat
      guestEmail
      guestPhone
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBookingDraft = /* GraphQL */ `
  mutation DeleteBookingDraft(
    $input: DeleteBookingDraftInput!
    $condition: ModelBookingDraftConditionInput
  ) {
    deleteBookingDraft(input: $input, condition: $condition) {
      id
      bookingID
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      repeat
      guestEmail
      guestPhone
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPetDraft = /* GraphQL */ `
  mutation CreatePetDraft(
    $input: CreatePetDraftInput!
    $condition: ModelPetDraftConditionInput
  ) {
    createPetDraft(input: $input, condition: $condition) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        repeat
        guestEmail
        guestPhone
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      bookingDraftPetsId
    }
  }
`;
export const updatePetDraft = /* GraphQL */ `
  mutation UpdatePetDraft(
    $input: UpdatePetDraftInput!
    $condition: ModelPetDraftConditionInput
  ) {
    updatePetDraft(input: $input, condition: $condition) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        repeat
        guestEmail
        guestPhone
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      bookingDraftPetsId
    }
  }
`;
export const deletePetDraft = /* GraphQL */ `
  mutation DeletePetDraft(
    $input: DeletePetDraftInput!
    $condition: ModelPetDraftConditionInput
  ) {
    deletePetDraft(input: $input, condition: $condition) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        repeat
        guestEmail
        guestPhone
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      bookingDraftPetsId
    }
  }
`;
export const createBlog = /* GraphQL */ `
  mutation CreateBlog(
    $input: CreateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    createBlog(input: $input, condition: $condition) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateBlog = /* GraphQL */ `
  mutation UpdateBlog(
    $input: UpdateBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    updateBlog(input: $input, condition: $condition) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlog = /* GraphQL */ `
  mutation DeleteBlog(
    $input: DeleteBlogInput!
    $condition: ModelBlogConditionInput
  ) {
    deleteBlog(input: $input, condition: $condition) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      bookingID
      parentSeriesId
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      userId
      repeat
      guestEmail
      guestPhone
      guestCity
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        nextToken
      }
      ownerDetail {
        id
        name
        email
        phone
        address
        city
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking(
    $input: UpdateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    updateBooking(input: $input, condition: $condition) {
      id
      bookingID
      parentSeriesId
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      userId
      repeat
      guestEmail
      guestPhone
      guestCity
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        nextToken
      }
      ownerDetail {
        id
        name
        email
        phone
        address
        city
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking(
    $input: DeleteBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    deleteBooking(input: $input, condition: $condition) {
      id
      bookingID
      parentSeriesId
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      userId
      repeat
      guestEmail
      guestPhone
      guestCity
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        nextToken
      }
      ownerDetail {
        id
        name
        email
        phone
        address
        city
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPet = /* GraphQL */ `
  mutation CreatePet(
    $input: CreatePetInput!
    $condition: ModelPetConditionInput
  ) {
    createPet(input: $input, condition: $condition) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        parentSeriesId
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        userId
        repeat
        guestEmail
        guestPhone
        guestCity
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      bookingPetsId
      owner
    }
  }
`;
export const updatePet = /* GraphQL */ `
  mutation UpdatePet(
    $input: UpdatePetInput!
    $condition: ModelPetConditionInput
  ) {
    updatePet(input: $input, condition: $condition) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        parentSeriesId
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        userId
        repeat
        guestEmail
        guestPhone
        guestCity
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      bookingPetsId
      owner
    }
  }
`;
export const deletePet = /* GraphQL */ `
  mutation DeletePet(
    $input: DeletePetInput!
    $condition: ModelPetConditionInput
  ) {
    deletePet(input: $input, condition: $condition) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        parentSeriesId
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        userId
        repeat
        guestEmail
        guestPhone
        guestCity
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      bookingPetsId
      owner
    }
  }
`;
export const createContactQuery = /* GraphQL */ `
  mutation CreateContactQuery(
    $input: CreateContactQueryInput!
    $condition: ModelContactQueryConditionInput
  ) {
    createContactQuery(input: $input, condition: $condition) {
      id
      name
      email
      phone
      subject
      message
      queryStatus
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateContactQuery = /* GraphQL */ `
  mutation UpdateContactQuery(
    $input: UpdateContactQueryInput!
    $condition: ModelContactQueryConditionInput
  ) {
    updateContactQuery(input: $input, condition: $condition) {
      id
      name
      email
      phone
      subject
      message
      queryStatus
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteContactQuery = /* GraphQL */ `
  mutation DeleteContactQuery(
    $input: DeleteContactQueryInput!
    $condition: ModelContactQueryConditionInput
  ) {
    deleteContactQuery(input: $input, condition: $condition) {
      id
      name
      email
      phone
      subject
      message
      queryStatus
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUnavailablity = /* GraphQL */ `
  mutation CreateUnavailablity(
    $input: CreateUnavailablityInput!
    $condition: ModelUnavailablityConditionInput
  ) {
    createUnavailablity(input: $input, condition: $condition) {
      id
      title
      start
      end
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateUnavailablity = /* GraphQL */ `
  mutation UpdateUnavailablity(
    $input: UpdateUnavailablityInput!
    $condition: ModelUnavailablityConditionInput
  ) {
    updateUnavailablity(input: $input, condition: $condition) {
      id
      title
      start
      end
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteUnavailablity = /* GraphQL */ `
  mutation DeleteUnavailablity(
    $input: DeleteUnavailablityInput!
    $condition: ModelUnavailablityConditionInput
  ) {
    deleteUnavailablity(input: $input, condition: $condition) {
      id
      title
      start
      end
      type
      createdAt
      updatedAt
    }
  }
`;
