import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

import thunk from 'redux-thunk';
import './index.css';
import App from './App';
import apiData from './store/reducers/apiData';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import awsConfig from './aws-exports';
import store from './store';
// alert(window.location.hostname)
const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
  
  // Assuming you have two redirect URIs, and the first is for localhost and second is for production
  const [
    productionRedirectSignIn,
    localRedirectSignIn,
    
  ] = awsConfig.oauth.redirectSignIn.split(",");
  
  const [

    productionRedirectSignOut,
    localRedirectSignOut,
  ] = awsConfig.oauth.redirectSignOut.split(",");
  
  const updatedAwsConfig = {
    ...awsConfig,
    oauth: {
      ...awsConfig.oauth,
      redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
      redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
    }
  }
  
  Amplify.configure(updatedAwsConfig);
let persistor = persistStore(store)

const app = (
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <Authenticator.Provider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Authenticator.Provider>
        </PersistGate>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
