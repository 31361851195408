import React from 'react';
import {  Toast, ToastContainer } from 'react-bootstrap';

const ConfirmationToast = (props: any) => {
    return (
        <ToastContainer position="top-end" className="p-3">
            <Toast show={props.showToast}
                onClose={props.toggleConfirmation}
                bg={props.type}
            >
                <Toast.Header>
                    <strong className="me-auto">{props.title}</strong>
                </Toast.Header>
                <Toast.Body>{props.description}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default ConfirmationToast;