import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import ProtectedArea from '../../hoc/protected-area';
import { Form, Toast, Spinner, ToastContainer } from 'react-bootstrap';
import { Formik, Field, FieldArray, ErrorMessage, Form as FormFormik } from "formik";
import { useFormFields } from "../../libs/hooksLib";
import { onError } from "../../libs/errorLib";
import "./change-email.css";
import { Button } from "@aws-amplify/ui-react";
import * as Yup from "yup";
import ConfirmationToast from "../../components/UI/ConfirmationToast";
import { RiLoader5Line } from "react-icons/ri";
const EmailSchema = Yup.object().shape({
    email: Yup.string()
        .required("Email is required field.")
});
const CodeSchema = Yup.object().shape({
    code: Yup.string()
        .required("Code is required field.")
});
export default function ChangeEmail(props: any) {
    const [show, setShow] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    
    const [codeSent, setCodeSent] = useState(false);
    let [initialValue, setInitialValue] = useState({
        code: '',
        email: ''
    });
    const [toastData, setToastData] = useState<any>({
        title: '',
        desciption: '',
        type: '',
        showToast: false
    })
    const toggleConfirmation = () => setToastData({...toastData,showToast:!toastData.showToast});

    const [isConfirming, setIsConfirming] = useState(false);
    const [changedEmail, setChangedEmail] = useState('');
    const [isSendingCode, setIsSendingCode] = useState(false);

    async function populateEmail() {
        const user = await Auth.currentAuthenticatedUser();
        const u = { ...initialValue, email: user.attributes.email }
        // console.log(u)
        setInitialValue(u)
    }
    useEffect(() => {
        populateEmail()
    }, [])


    async function handleUpdateClick(values: any) {
        setShow(true);
        setIsSendingCode(true);
        try {
            const user = await Auth.currentAuthenticatedUser();
            const response = await Auth.updateUserAttributes(user, { email: values.email });
            console.log(response)
            setCodeSent(true);
            setShow(false);
            setChangedEmail(values.email)

        } catch (error) {
            onError(error);
            setIsSendingCode(false);
        }
    }

    async function handleConfirmClick(values: any) {
        setShow(true);
        setIsConfirming(true);
        try {
            const response = await Auth.verifyCurrentUserAttributeSubmit("email", values.code);
            console.log(response)
            setToastData({
                title: 'Change Email',
                description: 'Your email has beed changed successfully.',
                type: 'success',
                showToast: true
            });
            setShow(false);
            props.history.push("/edit-profile");
        } catch (error) {
            // onError(error);
            setToastData({
                title: 'Error',
                description: 'Something went wrong! Please try again.',
                type: 'danger',
                showToast: true
            });
            setIsConfirming(false);
        }
    }

    function renderUpdateForm() {
        return (
            <Formik
                initialValues={initialValue}
                validationSchema={EmailSchema}
                onSubmit={(values, { resetForm }) => handleUpdateClick(values)}
            >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (

                    <FormFormik>
                        <div className="row">
                            <div className="col mb-10">
                                <Field
                                    name={`email`}
                                >{({ field, formProps }: any) => (
                                    <Form.Group controlId={`email`} className="mb-3">
                                        <div className="input-group contactme-form-email">
                                            <span className="input-group-text"></span>
                                            <Form.Control type="text" placeholder="Enter email" value={field.value} onChange={field.onChange} />
                                        </div>
                                        <ErrorMessage name={`email`} component="div" className={"error"} />
                                    </Form.Group>
                                )}
                                </Field>
                            </div>
                        </div>
                        <div className="text-center">
                            <Button className={show?"btn btn-light":"bg-theme1 text-white"}
                                type="submit"
                                // isLoading={isSendingCode}
                                disabled={initialValue.email == values.email}
                            >
                                {show ?
                                    <RiLoader5Line className='mr-2' style={{color:'#92cff7'}}/> : null
                                }   {show?<span>Sending verification code...</span>:<span>Update Email</span>}
                            </Button>

                        </div>

                    </FormFormik>
                )}
            </Formik>
        );
    }

    function renderConfirmationForm() {
        return (

            <Formik
                initialValues={initialValue}
                validationSchema={CodeSchema}
                onSubmit={(values, { resetForm }) => handleConfirmClick(values)}
            >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (

                    <FormFormik>
                        Please check your email ({changedEmail}) for the confirmation code.
                        <div className="row">

                            <div className="col mb-10">
                                <Field
                                    name={`code`}
                                >{({ field, formProps }: any) => (
                                    <Form.Group controlId={`code`} className="mb-3">
                                        <div className="input-group contactme-form-message">
                                            <Form.Control type="text" placeholder="Confirmation Code" value={field.value} onChange={field.onChange} />
                                        </div>
                                        <ErrorMessage name={`code`} component="div" className={"error"} />
                                    </Form.Group>
                                )}
                                </Field>
                            </div>
                        </div>
                        <div className="text-center">
                            <button className={show?"btn btn-light":"btn btn-success"} disabled={show?true:false}>
                                {show ?
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : null
                                }
                                 {show?<span>Updating...</span>:<span>Update Email</span>}
                                </button>
                        </div>

                    </FormFormik>
                )}
            </Formik>


        );
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <ProtectedArea>
                    <div className="ChangeEmail changee-mailblock edit-profile-title">
                      <h2 className="text-center text-theme1">CHANGE <span className="text-dark">EMAIL</span> </h2>
                        {!codeSent ? renderUpdateForm() : renderConfirmationForm()}
                    </div>
                </ProtectedArea>
            </div>
            <ConfirmationToast
                title={toastData.title}
                description={toastData.description}
                type={toastData.type}
                showToast={toastData.showToast}
                toggleConfirmation={toggleConfirmation}
            />
            {/* <ToastContainer position="top-end" className="p-3">
                <Toast show={showConfirmation}
                    onClose={toggleConfirmation}
                    bg={'success'}
                >
                    <Toast.Header>
                        <strong className="me-auto">Change Email</strong>
                    </Toast.Header>
                    <Toast.Body>Your Email successfully done !</Toast.Body>
                </Toast>
            </ToastContainer>
            <ToastContainer position="top-end" className="p-3">
                <Toast onClose={toggleError}
                    show={showError}
                    animation={false}
                    bg={'danger'}>
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body className={'text-white'}>Something went wrong! Please try again..</Toast.Body>
                </Toast>
            </ToastContainer> */}
        </div>
    );
}