import React from "react";
import {Box, Email, Item,Span } from "react-html-email";
type Props = {
    subject: string;
    mobileNumber: string;
    emailAddress: string;
    name:string;
    message:string,
};
const containerStyle = {
    backgroundColor: "#F5F8FA",
    width: "100%",
};
const emailHeadCSS = `
  body {
    background-color: #F5F8FA;
  }
`.trim();
const ptext={
    fontSize:'18px',
    padding:'10px 15px',
}
const backgroundStyle = {
    WebkitBoxShadow: "6px 6px 40px 3px rgba(140, 152, 164, 0.2)",
    backgroundColor: "#FFF",
    borderRadius: 7,
    boxShadow: "6px 6px 40px 3px rgba(140, 152, 164, 0.2)",
    margin: "0 auto",
    width: "100%",
    padding: "0 32px",
};
const ContactEmail = ({
    subject,
    mobileNumber,
    emailAddress,
    name,
    message,
}: Props) => (
    <Box align="center" style={containerStyle}>
            <Email align="center" headCSS={emailHeadCSS} title={subject}>
            <Item align="center">
                <Box style={backgroundStyle}>
                    <Item style={{ height: 40,backgroundColor: '#5b8a39',textAlign: 'center' }} >
                        <Span fontSize={22} fontWeight="bold" style={{color:'#fff'}}>Contact Us to LEXI NATION</Span>
                    </Item>
                    <Item style={{ height: 25 }} />
                    <Item>
                    <Span style={ptext}>Full Name: {name}</Span>
                    </Item>
                    <Item style={{ height: 10 }} />
                    <Item>
                        <Span style={ptext}>Contact Phone: {mobileNumber}</Span>
                    </Item>
                    <Item style={{ height: 10 }} />
                    <Item>
                        <Span style={ptext}>Email Address: {emailAddress}</Span>
                    </Item>
                    <Item style={{ height: 10 }} />
                    <Item>
                        <Span style={ptext}> Message: {message}</Span>
                    </Item>
                    <Item style={{ height: 50 }} />
                </Box>
            </Item>
            </Email>
    </Box>
);
export default ContactEmail;