import { Appointment } from "../interfaces";
import _ from 'lodash';
export const updateObject = (oldObject: object, updatedProperties: object) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};


export const bookingHoursMap: { [key: number]: string } = { 30: "30 Min", 60: "1 Hour", 120: "2 Hours", 180: "3 Hours" };



export function scheduleAppointment(appointments: Appointment[], startDate: Date, length: number): boolean {
    const endDate = new Date(startDate.getTime() + length * 60 * 1000);
    for (const appointment of appointments) {
        const appointmentEndDate = new Date(appointment.start.getTime() + appointment.length * 60 * 1000);
        if (startDate >= appointment.start && startDate < appointmentEndDate) {
            return false;
        }
        if (endDate > appointment.start && endDate <= appointmentEndDate) {
            return false;
        }
    }
    return true;
}
export function getUserAttribute(attributes: any, key: string) {
    let attributeObj = _.find(attributes, o => o.Name === key);
    if (attributeObj)
        return attributeObj.Value
    return
}
export function getRepeatDuration(value: any) {
    let output = null;
    let suffix = null;
    if (_.includes(value, "w")) suffix = 'Week(s)'
    else if (_.includes(value, "m")) suffix = 'Month(s)'
    output = `${_.parseInt(value || "")}  ${suffix}`
    return output
}