import React from 'react';
const RecordNotFound = (props: any) => {
    return (
        <>
            <div className={`ml-2 mr-2 text-center alert alert-${props.type}`} role="alert">
                {props.title}
            </div>
        </>
    );
};

export default RecordNotFound;