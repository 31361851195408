export const getBookingCustom = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      bookingID
      parentSeriesId
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      userId
      repeat
      guestEmail
      guestPhone
      guestCity
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        items {
            id
            petType
            petName
            petNeeds
            bookingPetsId
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
      ownerDetail {
        id
        name
        email
        phone
        address
        city
      }
    }
  }
`;
export const listBookingsCustom = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bookingID
        parentSeriesId
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        userId
        repeat
        guestEmail
        guestPhone
        guestCity
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
        pets{
          items {
              id
              petType
              petName
              petNeeds
              bookingPetsId
          }
        }
        owner
        ownerDetail {
          id
          name
          email
          phone
          address
          city
        }
      }
      nextToken
    }
  }
`;

export const createBookingCustom = /* GraphQL */ `
  mutation CreateBooking(
    $input: CreateBookingInput!
    $condition: ModelBookingConditionInput
  ) {
    createBooking(input: $input, condition: $condition) {
      id
      bookingID
      parentSeriesId
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      userId
      repeat
      guestEmail
      guestPhone
      guestCity
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        items {
            petType
            petName
            petNeeds
        }
      }
      ownerDetail {
        id
        name
        email
        phone
        address
        city
      }
      createdAt
      updatedAt
      owner
    }
  }
`;