import { Amplify, API, Auth, graphqlOperation } from 'aws-amplify'
import { parse } from 'graphql'
import _ from 'lodash'
import moment from 'moment'
import { createBooking, createPet, deleteBooking, updateBooking, createContactQuery, deletePet, updatePet, updateContactQuery, createUnavailablity, deleteUnavailablity, createBlog, deleteContactQuery, createBookingDraft, createPetDraft } from '../graphql/mutations'
import { getStripeConfig, getCreatePaymentIntent, listPets, listContactQueries, getUnavailablity, listUnavailablities, listBlogs } from '../graphql/queries'
import { getBookingCustom as getBooking, listBookingsCustom as listBookings } from '../graphql/queries-custom'
import { getLocalData, storeLocalData } from './global-storage'
import { Appointment, Blog, BookingData, ContactData, PetData, Unavailablity } from '../interfaces';
import { GraphQLResult } from "@aws-amplify/api";
import { number } from 'yup'
import { OnCreateBookingSubscription } from '../API'
import { onCreateBooking } from '../graphql/subscriptions'
import { GraphQLSubscription } from '@aws-amplify/api';
/**
items {
    id
    petType
    petName
    petNeeds
    bookingPetsId
}
 */
const sub = API.graphql<GraphQLSubscription<OnCreateBookingSubscription>>(
    graphqlOperation(onCreateBooking)
).subscribe({
    next: (payload) => {
        let element = payload.value.data?.onCreateBooking;
        console.log('onCreateBooking', element)

    }
});
sub.unsubscribe();

const testEmailAddress = 'lexi@lexination.com';
let nextToken: any;
async function addEditBookingApi(bookingData: BookingData, deletedPets: string[]) {
    try {
        let authUser = await checkLogin();
        let allPets: PetData[] | undefined = bookingData.pets;
        delete bookingData.pets;
        delete bookingData.createdAt;
        delete bookingData.updatedAt;
        if (bookingData.id) {
            bookingData = _.omit(bookingData, ['owner']);
            let response = (await API.graphql({
                query: updateBooking,
                variables: { input: bookingData },
                authMode: (authUser) ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
            })) as GraphQLResult<any>;
            console.log('update pets:', response);
            for (let index = 0; index < deletedPets.length; index++) {
                const element = deletedPets[index];
                await API.graphql({
                    query: deletePet,
                    variables: { input: { id: element } },
                    authMode: (authUser) ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
                })
            }
            for (let index = 0; index < _.size(allPets); index++) {
                const element = allPets?.[index];
                if (element?.id) {
                    delete element.createdAt;
                    delete element.updatedAt;
                    await API.graphql({
                        query: updatePet,
                        variables: { input: element },
                        authMode: (authUser) ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
                    })
                } else {
                    await API.graphql({
                        query: createPet,
                        variables: { input: { ...element, bookingPetsId: bookingData.id } },
                        authMode: (authUser) ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
                    })
                }
            }
            return response.data?.updateBooking?.id
        } else {
            bookingData.bookingID = `LN${moment().format('YYMMDDHHmmss')}`;
            bookingData.bookingStatus = `Active`;
            bookingData.paymentStatus = `Unpaid`;
            const currentUser = await getLoggedInUserId()
            if (bookingData.reserveUser == "account" && !currentUser) {
                // bookingData.bookingStatus = `Draft`;
                // let response = (await API.graphql({
                //     query: createBookingDraft,
                //     variables: { input: bookingData },
                //     // authMode: (authUser) ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
                // })) as GraphQLResult<any>;
                // for (let index = 0; index < _.size(allPets); index++) {
                //     const element = allPets?.[index];
                //     await API.graphql({
                //         query: createPetDraft,
                //         variables: { input: { ...element, bookingDraftPetDraftsId: response.data.createBookingDraft.id } },
                //         // authMode: (authUser) ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
                //     })
                // }
                bookingData.pets = allPets;
                storeLocalData('lastBookingData', JSON.stringify(bookingData))
                storeLocalData('lastBookingType', 'Draft')
                // return response.data?.createBookingDraft?.id
            } else {
                const lastBookingId = await createBookingApi(bookingData, allPets);
                if (bookingData && bookingData.repeat) {
                    let parentSeriesId = lastBookingId;
                    // let allPets: PetData[] | undefined = element.pets;
                    let repeatInterval: number = 1;
                    let daysOrMonth: moment.unitOfTime.DurationConstructor = "days"
                    let maxLength = 0;
                    let multiplier = 30;
                    if (_.includes(bookingData.repeatDuration, "w")) multiplier = 7
                    else if (_.includes(bookingData.repeatDuration, "m")) multiplier = 30

                    switch (bookingData.repeat) {
                        case 'daily':
                            repeatInterval = 1;
                            maxLength = (_.parseInt(bookingData.repeatDuration || "") * multiplier)
                            break;
                        case 'weekly':
                            repeatInterval = 7;
                            maxLength = (_.parseInt(bookingData.repeatDuration || "") * multiplier) / 7
                            break;
                        case 'monthly':
                            repeatInterval = 1;
                            maxLength = _.parseInt(bookingData.repeatDuration || "")
                            daysOrMonth = 'months'
                            break;
                        default:
                            repeatInterval = 1;
                            break;
                    }

                    for (let i = 1; i < maxLength; i++) {
                        let _startDate = moment(bookingData.bookingDate, "YYYY-MM-DD") //.tz(timeZone)
                        const start = _startDate.add(i * repeatInterval, daysOrMonth).format("YYYY-MM-DD");
                        const newBookingData = { ...bookingData, bookingDate: start, parentSeriesId }
                        await createBookingApi(newBookingData, allPets)

                    }

                }
                return lastBookingId

            }


        }

    } catch (err) {
        console.error('[service] Error in addEditBooking:', err)
        return Error("[service] Error in addEditBooking")
    }
}

async function createBookingApi(bookingData: BookingData, allPets: PetData[] | undefined) {
    let authUser = await checkLogin()
    let response = (await API.graphql({
        query: createBooking,
        variables: { input: bookingData },
        authMode: (authUser) ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY'
    })) as GraphQLResult<any>;
    for (let index = 0; index < _.size(allPets); index++) {
        const element = allPets?.[index];
        await API.graphql({
            query: createPet,
            variables: { input: { ...element, bookingPetsId: response.data.createBooking.id } },
            authMode: (authUser) ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY'
        })
    }
    return response.data?.createBooking?.id
}

async function checkLogin() {
    try {
        await Auth.currentAuthenticatedUser();
        return true;
    } catch {
        return false;
    }
}

async function getBookingByIdApi(bookingId: string) {
    try {
        let authUser = await checkLogin()
        let response = (await API.graphql({
            query: getBooking,
            variables: { id: bookingId },
            authMode: (authUser) ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY'
        })) as GraphQLResult<any>
        response.data.getBooking.pets = response.data.getBooking.pets.items;
        return response.data.getBooking
    } catch (err) {
        console.error('[service] Error in getting booking by id:', err)
        return Error("[service] Error in getting booking by id")
    }
}

async function getBookingsApi(id: string | undefined = undefined, bookingStatus: string | undefined = undefined, bookingDate: string | undefined = undefined, isUser: boolean | undefined = undefined) {
    type filter = {
        bookingID: object,
        id?: object,
        bookingStatus?: object,
        bookingDate?: object,
    }
    try {
        let authUser = await checkLogin()
        let filter: filter = { bookingID: { attributeExists: true } }

        if (bookingStatus) filter.bookingStatus = { eq: bookingStatus }
        if (bookingDate) filter.bookingDate = { ge: bookingDate }
        if (id) filter.id = { ne: id }
        console.log("[booking] getBookingsApi filter:", filter)

        let response = (await API.graphql({
            query: listBookings,
            variables: { filter },
            authMode: (isUser) ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY'
        })) as GraphQLResult<any>;
        const resItems = response.data.listBookings.items.map((o: any) => {
            o.pets = o.pets.items;
            return o;
        })
        console.log("[booking] getBookingsApi output:", resItems)
        return resItems
    } catch (err) {
        console.error('[service] Error in getting getMyBookings : ', err)
        return Error("[service] Error in getting getMyBookings")
    }
}

async function getBookingsListApi(limit: number, nextToken: any) {
    try {
        let response = (await API.graphql({
            query: listBookings,
            variables: { limit, nextToken },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })) as GraphQLResult<any>;
        // const resItems = response.data.listBookings.items.map((o: any) => {
        //     o.pets = o.pets.items;
        //     return o;
        // })
        console.log("[booking] getBookingsApi output:", response.data.listBookings)
        return response.data.listBookings
    } catch (err) {
        console.error('[service] Error in getting getMyBookings : ', err)
        return Error("[service] Error in getting getMyBookings")
    }
}
async function updateBookingsApi(input: object) {
    try {
        let response = (await API.graphql({ query: updateBooking, variables: { input }, authMode: 'AMAZON_COGNITO_USER_POOLS' })) as GraphQLResult<any>;
        const resItems = response.data.listBookings.items
        return resItems
    } catch (err) {
        console.error('[service] Error in updateBookingsApi : ', err)
        return Error("[service] Error in updateBookingsApi")
    }
}

async function deleteBookingsApi(id: string | undefined) {
    if (!id) return
    try {
        let response = (await API.graphql({
            query: deleteBooking,
            variables: { input: { id } },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })) as GraphQLResult<any>;
        return response
    } catch (err) {
        console.error('[service] Error in deleting bookings : ', err)
        return Error("[service] Error in deleting bookings")
    }
}
async function deleteBookingSeriesApi(id: string | undefined, parentSeriesId: string | undefined) {
    if (!parentSeriesId) parentSeriesId = id;
    try {
        let response1 = (await API.graphql({
            query: deleteBooking,
            variables: { input: { id: parentSeriesId } },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })) as GraphQLResult<any>;
        console.log('delete response main;', parentSeriesId)
        let response = (await API.graphql({
            query: listBookings,
            variables: { filter: { parentSeriesId: { eq: parentSeriesId } } },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })) as GraphQLResult<any>;

        const ids = _.map(response.data.listBookings.items, 'id')

        for (let index = 0; index < ids.length; index++) {
            const id = ids[index];
            if (id) {
                let response2 = (await API.graphql({
                    query: deleteBooking,
                    variables: { input: { id } },
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                })) as GraphQLResult<any>;
                console.log('delete response2;', response2)
            }
        }


        return response1
    } catch (err) {
        console.error('[service] Error in deleting bookings : ', err)
        return Error("[service] Error in deleting bookings")
    }
}
async function updateBookingSeriesApi(id: string | undefined, bookingStatus: string | undefined, parentSeriesId: string | undefined) {
    if (!parentSeriesId) parentSeriesId = id;
    try {
        let response1 = (await API.graphql({ query: updateBooking, variables: { input: { id: parentSeriesId, bookingStatus } }, authMode: 'AMAZON_COGNITO_USER_POOLS' })) as GraphQLResult<any>;

        console.log('update response main:', parentSeriesId)
        let response = (await API.graphql({
            query: listBookings,
            variables: { filter: { parentSeriesId: { eq: parentSeriesId } } },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })) as GraphQLResult<any>;

        const ids = _.map(response.data.listBookings.items, 'id')

        for (let index = 0; index < ids.length; index++) {
            const id = ids[index];
            if (id) {
                let response2 = (await API.graphql({ query: updateBooking, variables: { input: { id, bookingStatus } }, authMode: 'AMAZON_COGNITO_USER_POOLS' })) as GraphQLResult<any>;
                console.log('update response2:', response2)
            }
        }


        return response1
    } catch (err) {
        console.error('[service] Error in deleting bookings : ', err)
        return Error("[service] Error in deleting bookings")
    }
}
async function getStripeConfigApi(id: string) {
    // try {
    //     let response = await API.graphql(graphqlOperation(getStripeConfig, { path: "/config", httpMethod: "GET" }))
    //     console.log("getStripeConfig", response)
    //     return response
    // } catch (err) {
    //     console.error('[service] Error in getStripeConfigApi : ', err)
    //     return Error("[service] Error in getStripeConfigApi")
    // }

    try {
        let response = await API.get('stripe', '/stripe-config', {})
        console.log("getStripeConfigApi", { response })
        // console.log("getStripeConfig", response.data.getCreatePaymentIntent)
        return response
    } catch (err) {
        console.error('[service] Error in getStripeConfigApi : ', err)
        return Error("[service] Error in getStripeConfigApi")
    }
}
async function getCreatePaymentIntentApi(id: string) {

    // try {
    //     let response = await API.graphql(graphqlOperation(getCreatePaymentIntent, { path: "/create-payment-intent", amount: "200", httpMethod: "POST" }))
    //     console.log("getStripeConfig", parse(response))
    //     // console.log("getStripeConfig", response.data.getCreatePaymentIntent)
    //     return response
    // } catch (err) {
    //     console.error('[service] Error in getStripeConfigApi : ', err)
    //     return Error("[service] Error in getStripeConfigApi")
    // }

    try {
        if (!getLocalData('bookingId')) return "INVALID PAYMENT";
        let bookingData = await getBookingByIdApi(getLocalData('bookingId'));
        let response = await API.post('stripe', '/stripe-create-payment-intent', { body: { amount: _.get(bookingData, "data.getBooking.bookingAmount", 0) * 100 } })
        console.log("getCreatePaymentIntentApi", { response })
        // console.log("getStripeConfig", response.data.getCreatePaymentIntent)
        return response
    } catch (err) {
        console.error('[service] Error in getCreatePaymentIntentApi : ', err)
        return Error("[service] Error in getCreatePaymentIntentApi")
    }
}

async function listAllUsersApi(limit: number) {
    let apiName = 'AdminQueries';
    let path = '/listUsers';
    let myInit = {
        queryStringParameters: {
            "groupname": "Editors",
            "limit": limit,
            "token": nextToken
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    const { NextToken, ...rest } = await API.get(apiName, path, myInit);
    nextToken = NextToken;
    const response = { ...rest, ...{ nextToken: NextToken } };
    return response;
}
async function sendSampleEmailApi(message: string) {
    try {
        let response = await API.post('stripe', '/send-notification', {
            body: { toAddresses: testEmailAddress, subject: "Test Subject", message: message },
        })
        console.log("sendSampleEmailApi", { response })
        return response
    } catch (err) {
        console.error('[service] Error in sendSampleEmailApi : ', err)
        return Error("[service] Error in sendSampleEmailApi")
    }
}
async function sendContactEmailApi(message: string, subject: string) {
    try {
        let response = await API.post('stripe', '/send-notification', {
            body: { toAddresses: testEmailAddress, subject: subject, message: message },
        })
        console.log("sendContactEmailApi", { response })
        return response
    } catch (err) {
        console.error('[service] Error in sendContactEmailApi : ', err)
        return Error("[service] Error in sendContactEmailApi")
    }
}
async function sendAppointmentEmailApi(message: string, subject: string,) {
    try {
        let response = await API.post('stripe', '/send-notification', {
            body: { toAddresses: testEmailAddress, subject: subject, message: message },
        })
        console.log("sendAppointmentEmailApi", { response })
        return response
    } catch (err) {
        console.error('[service] Error in sendAppointmentEmailApi : ', err)
        return Error("[service] Error in sendAppointmentEmailApi")
    }
}
async function isUserLoggedIn() {
    try {
        await Auth.currentAuthenticatedUser();
        return true;
    } catch (e) {
        console.error("Error in isUserLoggedIn", e)
        return false;
    }
}

async function getLoggedInUserId() {
    try {
        const user = await Auth.currentAuthenticatedUser();
        return user.attributes.sub;
    } catch (e) {
        console.error("Error in isUserLoggedIn", e)
        return false;
    }
}
async function createContactQueryApi(contactData: ContactData) {
    try {
        let authUser = await checkLogin()
        if (authUser) {
            const currentUser = await getLoggedInUserId()
            if (currentUser) contactData.userId = currentUser;
        }

        contactData.queryStatus = "Pending"
        let response = (await API.graphql({
            query: createContactQuery,
            variables: { input: contactData },
            // authMode: (authUser) ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
        })) as GraphQLResult<any>;
        return response.data?.createContactQuery?.id
    } catch (err) {
        console.error('[service] Error in creating ContactQuery:', err)
        return Error("[service] Error in creating ContactQuery")
    }
}
async function getContactQueryApi(limit: number, nextToken: any) {
    try {
        const response = (await API.graphql({
            query: listContactQueries,
            variables: { nextToken, limit },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })) as GraphQLResult<any>;
        const resItems = response.data.listContactQueries
        return resItems
    } catch (err) {
        console.error('[service] Error in getting getContactQueryApi : ', err)
        return Error("[service] Error in getting getContactQueryApi")
    }
}
async function updateContactQueryApi(input: object) {
    try {
        let response = (await API.graphql({
            query: updateContactQuery,
            variables: { input },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })) as GraphQLResult<any>;
        const resItems = response.data.listContactQueries.items
        return resItems
    } catch (err) {
        console.error('[service] Error in updateContactQueryApi : ', err)
        return Error("[service] Error in updateContactQueryApi")
    }
}
async function getAllAppointmentsApi(id: string | undefined = undefined) {
    const appointments: Appointment[] = [];
    let bookings = await getBookingsApi(id, 'Active', moment().format("YYYY-MM-DD"));
    let unavailable = await getUnavailablityApi();
    console.log("[booking] getAllAppointmentsApi:unavailable ", unavailable)
    console.log("[booking] getAllAppointmentsApi:bookings ", bookings)
    bookings.forEach((element: BookingData) => {

        let _startDate = moment(`${element.bookingDate} ${element.bookingStartTime}`, "YYYY-MM-DD hh:mm a")//.tz(timeZone)
        const start = new Date(_startDate.format("YYYY-MM-DDTHH:mm:00"));
        let _endDate = moment(`${element.bookingDate} ${element.bookingEndTime}`, "YYYY-MM-DD hh:mm a")//.tz(timeZone)
        const end = new Date(_endDate.format("YYYY-MM-DDTHH:mm:00"));
        appointments.push({
            start,
            length: element.bookingLength + 30,
            end,
            title: `${(element.serviceType === "dog-walking") ? "Dog Walking" : "House Visit"}  ${_.chain(element.pets).map("petName").join(",").value()}`,
            description1: `${_.compact([element?.ownerDetail?.name, element?.ownerDetail?.email]).join(",")}`,
            description2: `${_.compact([element.ownerDetail?.address, element.ownerDetail?.city]).join(",")}`,
            type: 'pet',
            id: element.id
        });
        /*
        let repeatInterval: number = 1;
        let daysOrMonth: moment.unitOfTime.DurationConstructor = "days"
        let maxLength = 0;
        let multiplier = 30;
        if (_.includes(element.repeatDuration, "w")) multiplier = 7
        else if (_.includes(element.repeatDuration, "m")) multiplier = 30

        switch (element.repeat) {
            case 'daily':
                repeatInterval = 1;
                maxLength = _.parseInt(element.repeatDuration || "") * multiplier
                break;
            case 'weekly':
                repeatInterval = 7;
                maxLength = (_.parseInt(element.repeatDuration || "") * multiplier) / 7
                break;
            case 'monthly':
                repeatInterval = 1;
                maxLength = _.parseInt(element.repeatDuration || "")
                daysOrMonth = 'months'
                break;
            default:
                repeatInterval = 1;
                break;
        }

        for (let i = 1; i < maxLength; i++) {
            let _startDate = moment(`${element.bookingDate} ${element.bookingStartTime}`, "YYYY-MM-DD hh:mm a")//.tz(timeZone)
            const start = new Date(_startDate.add(i * repeatInterval, daysOrMonth).format("YYYY-MM-DDTHH:mm:00"));
            let _endDate = moment(`${element.bookingDate} ${element.bookingEndTime}`, "YYYY-MM-DD hh:mm a")//.tz(timeZone)
            const end = new Date(_endDate.add(i * repeatInterval, daysOrMonth).format("YYYY-MM-DDTHH:mm:00"));
            appointments.push({
                start,
                length: element.bookingLength + 30,
                end,
                id: element.id,
                title: `${(element.serviceType === "dog-walking") ? "Dog Walking" : "House Visit"}  ${_.chain(element.pets).map("petName").join(",").value()}`,
                description1: `${_.compact([element?.ownerDetail?.name, element?.ownerDetail?.email]).join(",")}`,
                description2: `${_.compact([element.ownerDetail?.address, element.ownerDetail?.city]).join(",")}`,
                type: 'petcare'
            });
        }*/
    });

    unavailable.forEach((element: Unavailablity) => {
        let start = new Date(element.start)
        let end = new Date(element.end)
        var difference = end.getTime() - start.getTime(); // This will give difference in milliseconds
        var length = Math.round(difference / 60000);
        appointments.push({
            id: element.id,
            start,
            length,
            end,
            title: element.title,
            description1: '',
            description2: '',
            type: 'unavailable'
        });
    })

    console.log("[booking] getAllAppointmentsApi:appointments ", appointments)
    return appointments;
}
async function deleteContactApi(id: string | undefined = undefined) {
    if (!id) return
    try {
        let response = (await API.graphql({
            query: deleteContactQuery,
            variables: { input: { id } },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })) as GraphQLResult<any>;
        return response
    } catch (err) {
        console.error('[service] Error in deleting bookings : ', err)
        return Error("[service] Error in deleting bookings")
    }
}
async function createUnavailablityApi(start: Date, end: Date) {
    let unavailablityRecord: Unavailablity = {
        start,
        end,
        type: 'personal',
        title: 'Unavailable'
    };
    try {
        let response = (await API.graphql({
            query: createUnavailablity,
            variables: { input: unavailablityRecord },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        }
        )) as GraphQLResult<any>;
        return response.data?.createUnavailablity?.id
    } catch (err) {
        console.error('[service] Error in creating Unavailablity:', err)
        return Error("[service] Error in creating Unavailablity")
    }
}

async function getUnavailablityApi() {
    try {
        let authUser = await checkLogin()
        let response = (await API.graphql({
            query: listUnavailablities,
            variables: { filter: { start: { ge: moment().format("YYYY-MM-DD") } } },
            // authMode: (authUser) ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM'
        })) as GraphQLResult<any>;
        // let response = (await API.graphql({
        //     query: listUnavailablities,
        //     variables: {
        //         filter: { start: { ge: moment().format("YYYY-MM-DD") } }
        //     }
        // })) as GraphQLResult<any>;
        const resItems = response.data.listUnavailablities.items
        return resItems
    } catch (err) {
        console.error('[service] Error in getting getUnavailablityApi : ', err)
        return Error("[service] Error in getting getUnavailablityApi")
    }
}

async function fetchBlogsApi(limit: number, nextToken: string) {
    type BlogListInput = {
        limit: number;
        nextToken?: string;
    }
    const blogListInput: BlogListInput = { limit }
    if (nextToken) blogListInput.nextToken = nextToken
    // if(prevToken) blogListInput.prevToken = prevToken
    const result = (await API.graphql(
        graphqlOperation(listBlogs, blogListInput)
    )) as GraphQLResult<any>;;
    return result.data as {
        listBlogs: { items: Array<Blog>, nextToken: string }
    };
}
async function createBlogApi() {
    try {
        for (let index = 1; index < 5; index++) {
            await API.graphql({
                query: createBlog,
                variables: { input: { title: `t${index}`, content: `c${index}`, owner: 'rajesh_eck2000@yahoo.com' } },
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            })
            // await API.graphql(graphqlOperation(createBlog, { input: , 
            // authMode: 'AMAZON_COGNITO_USER_POOLS' }));
        }

    } catch (err) {
        console.error('[service] Error in creating Blog:', err)
        return Error("[service] Error in creating Blog")
    }
}
async function deleteUnavailabityApi(id: string | undefined) {
    if (!id) return
    try {
        let response = (await API.graphql({
            query: deleteUnavailablity,
            variables: { input: { id } },
            authMode: 'AMAZON_COGNITO_USER_POOLS'
        })) as GraphQLResult<any>;
        return response
    } catch (err) {
        console.error('[service] Error in deleting unavailability : ', err)
        return Error("[service] Error in deleting unavailability")
    }
}
async function updateUserApi(username: string, pathValue: string) {
    let apiName = 'AdminQueries';
    let path = pathValue;
    let myInit = {
        body: {
            "username": username,
            "groupname": "Editors"
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return await API.post(apiName, path, myInit);
}

export {
    addEditBookingApi,
    createBookingApi,
    getBookingByIdApi,
    getBookingsApi,
    updateBookingsApi,
    deleteBookingsApi,
    getStripeConfigApi,
    getCreatePaymentIntentApi,
    isUserLoggedIn,
    getLoggedInUserId,
    createContactQueryApi,
    sendSampleEmailApi,
    sendContactEmailApi,
    sendAppointmentEmailApi,
    listAllUsersApi,
    getContactQueryApi,
    updateContactQueryApi,
    getAllAppointmentsApi,
    deleteContactApi,
    createUnavailablityApi,
    getUnavailablityApi,
    fetchBlogsApi,
    createBlogApi,
    deleteUnavailabityApi,
    getBookingsListApi,
    updateUserApi,
    deleteBookingSeriesApi,
    updateBookingSeriesApi
}