import React, { useEffect, useState } from 'react';
import { sendSampleEmailApi } from '../../services';

const Policy = () => {
    const callSampleEmail = () => {
        //sendSampleEmailApi()
    }
    return (
        <div className="container mb-90">
            <h2 className='text-center'>Web Site Privacy Policy</h2>
            <div><p className='align-justify'><span className='font12'><span className='bgWhite'><span><span className='font10_5'><span><span>This privacy policy statement is part of our terms and conditions and may be amended from time to time.</span></span></span></span></span></span></p>
                <p className='align-justify'><span className='font12'><span className='bgWhite'><span><u><span className='font10_5'><span><span>Collection, Use and Storage of Information:</span></span></span></u><span className='font10_5'><span><span>&nbsp;For each visitor to our Web site, our Web server automatically recognizes only the visitor&#39;s domain name, but not the visitor&#39;s email address or other personally identifiable information unless the visitor makes that available. We will store only such information and the user&#39;s account name.</span></span></span></span></span></span></p>
                <p className='align-justify'><span className='font12'><span className='bgWhite'><span><span className='font10_5'><span><span>We may use the information we collect from visitors to our Web site to improve the content of our Web site, to customize the content and layout of our pages for each individual visitor, to administer our Web site, to analyze trends, and to notify visitors about updates to our Web site.</span></span></span></span></span></span></p>
                <p className='align-justify'><span className='font12'><span className='bgWhite'><span><u><span className='font10_5'><span><span>Disclosure of Information:</span></span></span></u><span className='font10_5'><span><span>&nbsp;We do not share with other organizations any information we obtain from visitors.</span></span></span></span></span></span></p>
                <p className='align-justify'><span className='font12'><span className='bgWhite'><span><u><span className='font10_5'><span><span>Links and Third party sites:</span></span></span></u><span className='font10_5'><span><span>&nbsp;This policy only addresses our activities from our servers. Other sites may have their own policies, which we do not control, and thus are not addressed by this policy. We do not link to third parties sites.</span></span></span></span></span></span></p>
                <p className='align-justify'><span className='font12'><span className='bgWhite'><span><u><span className='font10_5'><span><span>Review and Removal of Your Personally Identifiable Data.</span></span></span></u><span className='font10_5'><span><span>&nbsp;If you provide us with personally identifiable information to have us perform ongoing services to you, we will take reasonable steps to provide you with a way to review the information we have on file and either change or remove such data. </span></span></span></span></span></span></p>
                <p className='align-justify'><span className='font12'><span className='bgWhite'><span><u><span className='font10_5'><span><span>Cookies and Other Tracking Methods.</span></span></span></u><span className='font10_5'><span><span>&nbsp;We may install cookies on users&#39; browsers, but only to identify users by the login and password they use, and to prevent unauthorized access. Cookies do not spy on you or otherwise invade your privacy, and they do not invade your hard drive.</span></span></span></span></span></span></p>
                <p className='align-justify'><span className='font12'><span className='bgWhite'><span><u><span className='font10_5'><span><span>Security.</span></span></span></u><span className='font10_5'><span><span>&nbsp;We use industry-standard methods to protect your personally identifiable information (if any) from unauthorized access. Among other techniques, we usually store such information on a computer behind our &ldquo;firewall&rdquo; in a secure location, and we often restrict the number of persons internally who can access such data. Of course, there is no such thing as &ldquo;perfect security &rdquo; on the Internet.</span></span></span></span></span></span></p>
                <p className='align-justify'><span className='font12'><span className='bgWhite'><span><u><span className='font10_5'><span><span>Amendments.</span></span></span></u><span className='font10_5'><span><span>&nbsp;Amendments to this policy or an amended policy, if adopted, will be posted at this URL and will be effective when posted.</span></span></span></span></span></span></p>
                <p>&nbsp;</p>

            </div>
        </div>
    );

}

export default Policy;

