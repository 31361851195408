import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
interface PhoneNumberMaskProps {
    field: any;
    id: any;
    name: any;
    type: any;
    placeholder: any;
    setFieldValue: any;
}



const PhoneNumberMask: React.FC<PhoneNumberMaskProps> = ({ field, setFieldValue, ...props }) => {
    // const { setFieldValue } = form;
    const { value } = field;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = event.target;
        let newValue = value.replace(/\D/g, '');
        if (newValue.length > 10) {
            newValue = newValue.slice(0, 10);
        }
        if (newValue.length > 6) {
            newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(3, 6)}-${newValue.slice(6)}`;
        } else if (newValue.length > 3) {
            newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(3)}`;
        }
        setFieldValue(field.name, newValue);
    };

    const handleBlur = () => {
        let newValue = value;
        if (newValue.length === 10) {
            newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(3, 6)}-${newValue.slice(6)}`;
        }
        setFieldValue(field.name, newValue);
    };

    return (
        <Form.Control
            {...field}
            {...props}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
        />
    );
};
export default PhoneNumberMask;