import React, { useEffect, useState } from 'react';
import { Formik, Field, FieldArray, ErrorMessage, Form as FormFormik } from "formik";
import "bootstrap/dist/css/bootstrap.css";
import * as Yup from "yup";
import classes from '../../hoc/Layout/Layout.css'
import { Alert, Button, Card, Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import _ from 'lodash';
import moment from 'moment'
import { addEditBookingApi, isUserLoggedIn } from '../../services';
import { getLocalData, storeLocalData } from '../../services/global-storage';
import { useAuthenticator } from '@aws-amplify/ui-react';
const BookingSchema = Yup.object().shape({
    bookingDate: Yup.string()
        .required("Booking date is required"),
    bookingStartTime: Yup.string()
        .required("Booking start time is required"),
    bookingEndTime: Yup.string()
        .required("Booking end time is required"),
    guestEmail: Yup.string().when('reserveUser', {
        is: 'guest',
        then: Yup.string().required("Enter guest email address")
    }),
    guestPhone: Yup.string().when('reserveUser', {
        is: 'guest',
        then: Yup.string().required("Enter guest phone")
    }),
    pets: Yup.array()
        .of(
            Yup.object({
                petName: Yup.string().required('Pet name required'),
                petType: Yup.string().required('Pet type required'),
            })
        )
        .min(1, 'You need at least one pet'),
});
const Book = props => {
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const [show, setShow] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [quote, setQuote] = useState(0);
    const [serviceTime, setServiceTime] = useState(0);
    useEffect(() => {
        isUserLoggedIn().then(setIsLogin)
        return () => {
            console.log('This will be logged on unmount');
        };
    }, [authStatus])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const adjustQuoate = (values) => {
        let multiple = 15, offset = 10;
        let start = moment(`${values.bookingDate} ${values.bookingStartTime}`)
        let end = moment(`${values.bookingDate} ${values.bookingEndTime}`)
        if (_.size(values.pets) == 2) multiple = 25;
        let serviceTime = (end.diff(start, 'minutes'));
        setServiceTime(serviceTime)
        let cost = serviceTime / 30 * multiple + offset
        setQuote(cost.toFixed(1))
    }



    return (
        <>
            <style type="text/css">
                {`
                    .btn-flat {
                    background-color: red;
                    color: white;
                    float: right;
                    }

                    .btn-xxs {
                    padding: 0 .35rem;
                    font-size: 0.8rem;
                    }
                    .error {
                        color: red;
                        font-size: 0.8rem;
                    }
                `}
            </style>
            <Formik
                initialValues={{
                    bookingDate: moment().add(1, 'day').format("yyyy-MM-DD"),
                    bookingStartTime: "08:00",
                    bookingEndTime: "09:00",
                    pets: [{
                        petType: 'Cat',
                        petName: 'Milo',
                        petNeeds: "No medical attention needed"
                    }],
                    reserveUser: "account",
                    paymentMethod: "online",
                    repeat: 'none',
                    guestEmail: '',
                    guestPhone: ''

                }}
                validationSchema={BookingSchema}
                onSubmit={values => {
                    values.bookingAmount = parseInt(quote);
                    addEditBookingApi(values)
                        .then(bookingId => {
                            // storeLocalData("bookingId", bookingId);
                            storeLocalData("paymentMethod", values.paymentMethod);
                            if (values.reserveUser == "guest") {
                                if (values.paymentMethod == "online")
                                    props.history.push("/payment")
                                else
                                    props.history.push(`/booking-confirmation/${bookingId}`)
                            }
                            else {
                                if (!isLogin) props.history.push("/signin")
                                else if (values.paymentMethod == "online") props.history.push("/payment")
                                else props.history.push(`/booking-confirmation/${bookingId}`)
                            }
                        })
                        .catch(console.error)

                }}
            >
                {({ touched, errors, isSubmitting, values }) => (
                    <FormFormik>
                        <Card>
                            <Card.Body>
                                <Card.Title>Enter the date and time you need pet care</Card.Title>
                                <Card.Text>
                                    <Row>
                                        <Col>
                                            <Field
                                                name="bookingDate"
                                            >
                                                {({ field, formProps }) => (
                                                    <Form.Group controlId="bookingDate" className="mb-3">
                                                        <Form.Label>Date</Form.Label>
                                                        <Form.Control type="date" min={moment().format("yyyy-MM-DD")} value={field.value} onChange={field.onChange} />
                                                        <ErrorMessage name="bookingDate" component="div" className={"error"} />
                                                        {adjustQuoate(values)}
                                                    </Form.Group>
                                                )}
                                            </Field>


                                        </Col>
                                        <Col>
                                            <Field
                                                name="bookingStartTime"
                                            >
                                                {({ field, formProps }) => (
                                                    <Form.Group controlId="bookingStartTime" className="mb-3">
                                                        <Form.Label>Start Time</Form.Label>
                                                        <Form.Control type={'time'} value={field.value} min="08:00"  step="900" 
                                                            max="18:00" onChange={field.onChange} />
                                                    </Form.Group>
                                                )}
                                            </Field>
                                            <ErrorMessage name="bookingStartTime" />
                                        </Col>
                                        <Col>
                                            <Field
                                                name="bookingEndTime"

                                            >
                                                {({ field, formProps }) => (
                                                    <Form.Group controlId="bookingEndTime" className="mb-3">
                                                        <Form.Label>End Time</Form.Label>
                                                        <Form.Control type={'time'} value={field.value} onChange={field.onChange} min="08:00" max="18:00" step="900"  />
                                                        <ErrorMessage name="bookingEndTime" component="div" className={"error"} />
                                                    </Form.Group>
                                                )}
                                            </Field>

                                        </Col></Row>
                                    <Form.Text className="text-muted">
                                        If you need services for more than one hour, please email or text me directly at lexi@lexination.com or 415-717-9015.
                                    </Form.Text>
                                </Card.Text>
                            </Card.Body>
                        </Card>



                        <FieldArray
                            name="pets"
                            render={arrayHelpers => (
                                <>
                                    {values.pets.map((pet, index) => (
                                        <div key={index}>
                                            <Card style={{ marginTop: 10 }}>
                                                <Card.Body>
                                                    <Card.Title>
                                                        <Row style={{}}>
                                                            <Col>Pet {(index !== 0 && _.size(values.pets) > 1) ? `#${index + 1}` : ''} Information</Col>
                                                            <Col style={{ disply: 'flex', justifyContent: 'right', borderWidth: 1 }}>
                                                                {(index !== 0 && _.size(values.pets) > 1) ? <Button size="xxs" variant="flat" onClick={() => arrayHelpers.remove(index)}>x</Button> : null}
                                                            </Col>
                                                        </Row>

                                                    </Card.Title>
                                                    <Card.Text>

                                                        <Field
                                                            name={`pets[${index}].petType`}
                                                        >{({ field, formProps }) => (
                                                            <Form.Group controlId={`pets[${index}].petType`} className="mb-3">
                                                                <Form.Label>Type of pet: Dog, Cat, etc.</Form.Label>
                                                                <Form.Select value={field.value} onChange={field.onChange}>
                                                                    <option value="">--Select--</option>
                                                                    <option value="Dog">Dog</option>
                                                                    <option value="Cat">Cat</option>
                                                                    <option value="Goat">Goat</option>
                                                                </Form.Select>
                                                                <ErrorMessage name={`pets[${index}].petType`} component="div" className={"error"} />
                                                            </Form.Group>
                                                        )}</Field>

                                                        <Field
                                                            name={`pets[${index}].petName`}
                                                        >{({ field, formProps }) => (
                                                            <Form.Group controlId={`pets[${index}].petName`} className="mb-3">
                                                                <Form.Label>My pet's name</Form.Label>
                                                                <Form.Control type={'text'} placeholder="Please enter pet name" value={field.value} onChange={field.onChange} />
                                                                <ErrorMessage name={`pets[${index}].petName`} component="div" className={"error"} />
                                                            </Form.Group>
                                                        )}
                                                        </Field>
                                                        <Field
                                                            name={`pets[${index}].petNeeds`}
                                                        >{({ field, formProps }) => (
                                                            <Form.Group controlId={`pets[${index}].petNeeds`} className="mb-3">
                                                                <Form.Label>Special needs for my pet</Form.Label>
                                                                <Form.Control type={'text'} placeholder="For example need medical care" value={field.value} onChange={field.onChange} />
                                                            </Form.Group>
                                                        )}</Field>
                                                    </Card.Text>

                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))}
                                    {_.size(arrayHelpers.form.values.pets) == 1 && <div><Alert.Link href="#" onClick={() => {
                                        arrayHelpers.push({ petName: '', petType: '', petNeeds: '' })
                                        // adjustQuoate(arrayHelpers.form.values)
                                    }}>Click here</Alert.Link> if you have more pets needing care at the same date and time.</div>}

                                </>
                            )}
                        />

                        <br />
                        <Alert key={"info"} variant={"info"}>
                            Quote: The cost for your requested service is <big>${quote}</big>(Service time: {serviceTime} mins) <br />
                            <small>*Based on $25 first 30 minutes + $15 each additional 30 minutes for 1 pet; $35 first 30 minutes + $25 for two total pets</small>
                        </Alert>
                        <Form.Text>
                            No payment required at this time. You can pay for pet services after they are completed.
                        </Form.Text>

                        <Field
                            name="repeat"
                        >{({ field, formProps }) => (
                            <Form.Group controlId={`repeat`} className="mb-3">
                                <Form.Label>Repeat reservation</Form.Label>
                                <Form.Select value={field.value} onChange={field.onChange}>
                                    <option value="none">None</option>
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                </Form.Select>
                                <ErrorMessage name={`repeat`} component="div" className={"error"} />
                            </Form.Group>
                        )}</Field>
                        <Form.Group controlId={`repeat`} className="mb-3">
                            <Form.Label>Payment Method</Form.Label><br />
                            <Field type="radio" name="paymentMethod" value="online" /> Online &nbsp;
                            <Field type="radio" name="paymentMethod" value="offline" /> Offline
                        </Form.Group>
                        {!isLogin ? <div className="mb-3">
                            <Row style={{ }}>
                                <Col>
                                    <Field type="radio" name="reserveUser" value="guest" /> Continue as a guest
                                </Col>
                                <Col>
                                    <Field type="radio" name="reserveUser" value="account" /> Create an account or login to reserve this date and time.
                                </Col>
                            </Row>
                            {values.reserveUser == "guest" && <Card>
                                <Card.Body><Card.Text>
                                    <Field
                                        name={`guestEmail`}
                                    >{({ field, formProps }) => (
                                        <Form.Group controlId={`guestEmail`} className="mb-3">
                                            <Form.Label>Please enter your email</Form.Label>
                                            <Form.Control type={'email'} placeholder="Please enter your email (required)" value={field.value} onChange={field.onChange} />
                                            <ErrorMessage name={`guestEmail`} component="div" className={"error"} />
                                        </Form.Group>
                                    )}
                                    </Field>
                                    <Field
                                        name={`guestPhone`}
                                    >{({ field, formProps }) => (
                                        <Form.Group controlId={`guestPhone`} className="mb-3">
                                            <Form.Label>Please enter your phone</Form.Label>
                                            <Form.Control type={'tel'} placeholder="Please enter your phone (required)" value={field.value} onChange={field.onChange} />
                                            <ErrorMessage name={`guestPhone`} component="div" className={"error"} />
                                        </Form.Group>
                                    )}</Field></Card.Text></Card.Body>
                            </Card>}

                        </div> : null}
                        <Button variant="primary" type="submit">
                            Reserve the date and time
                        </Button>
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </FormFormik>

                )}
            </Formik>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        (required)
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Continue
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );

}

export default Book;

