
import React, { useEffect, useState } from 'react';
import { Formik, Field, FieldArray, ErrorMessage, Form as FormFormik, FormikProps, useFormik } from "formik";
import "bootstrap/dist/css/bootstrap.css";
import * as Yup from "yup";
import { Alert, Button, Spinner, CloseButton, Col, Form, Row, Toast, ToastContainer } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import _ from 'lodash';
import moment from 'moment-timezone'
import { addEditBookingApi, getAllAppointmentsApi, getBookingsApi, isUserLoggedIn, sendAppointmentEmailApi } from '../../services';
import { getLocalData, storeLocalData } from '../../services/global-storage';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Appointment, BookingData, KeyValuePair } from '../../interfaces';
import PhoneNumberMask from '../../components/UI/Elements/masked-phone';
import { renderEmail } from 'react-html-email';
import { bookingHoursMap, scheduleAppointment } from '../../store/utility'
import BookingEmail from '../Emails/booking-email';
import EventCalendarPopup from './event-calendar-popup';
import { RiLoader5Line } from "react-icons/ri";
import { Auth } from 'aws-amplify';
const timeZone = `PST8PDT`;
// const timeZone = `Asia/Mumbai`
const petRates: any = {
    'dog-walking': {
        "30": {
            1: 30,
            2: 40,
            3: 60,
        },
        "60": {
            1: 40,
            2: 60,
            3: 80,
        },
        "120": {
            1: 60,
            2: 80,
            3: 100,
        },
        "180": {
            1: 80,
            2: 100,
            3: 120,
        }
    },
    'house-visit': {
        "30": {
            'Dog': {
                1: 30,
                2: 40,
                3: 60,
            },
            'Cat': {

                1: 30,
                2: 30,
                3: 35,
            }

        },
        "60": {
            'Dog': {
                1: 40,
                2: 60,
                3: 80,
            },
            'Cat': {
                1: 35,
                2: 35,
                3: 40,
            }
        },
        "120": {
            'Dog': {
                1: 60,
                2: 80,
                3: 100,
            },
            'Cat': {
                1: 45,
                2: 45,
                3: 55,
            }
        },
        "180": {
            'Dog': {
                1: 80,
                2: 100,
                3: 120,
            },
            'Cat': {
                1: 60,
                2: 60,
                3: 65,
            }
        }
    }
}
const appointments: Appointment[] = [];


let optionArr: string[] = [];
let startDate = moment("2022-01-14 08:00 am", "YYYY-MM-DD hh:mm a")//.tz(timeZone);
// console.log(startDate)
optionArr.push(startDate.format("LT"))
for (let index = 0; index < 44; index++) {
    optionArr.push(startDate.add(15, 'minutes').format("LT"))
}
const nextDate: string = moment().add(1, 'day').format("yyyy-MM-DD");
const Book = (props: any) => {
    const [show, setShow] = useState(false);
    const [adminNavLinks, setAdminNavLinks] = React.useState<boolean>(false)
    const { user, signOut } = useAuthenticator((context) => [context.user]);
    console.log('user;', user)
    const loginUser = user?.attributes?.name || "Guest";
    // let role: string = user.getSignInUserSession()?.getIdToken().payload["cognito:groups"][0]
    const initialBookingValues: BookingData = {
        bookingDate: nextDate,
        bookingStartTime: "8:00 AM",
        bookingLength: 60,
        bookingEndTime: "9:00 AM",
        serviceType: 'dog-walking',
        bookingAmount: 0,
        pets: [{
            petType: 'Dog',
            petName: '',
            petNeeds: ""
        }],
        reserveUser: "account",
        paymentMethod: "offline",
        repeat: 'none',
        guestEmail: '',
        guestPhone: '',
        repeatDuration: '',
        guestCity: '',
        guestAddress: ''

    }
    let skipDates: any = {}
    const [formBookingData, setFormBookingData] = useState<BookingData>(initialBookingValues);
    const [appointments, setAppointments] = useState<Appointment[]>([]);
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const [isLogin, setIsLogin] = useState(false);
    const [showEventCalendarPopup, setShowEventCalendarPopup] = useState<boolean>(false);
    const [showCalendarLink, setShowCalendarLink] = useState(false);
    const [deletedPets, setDeletedPets] = useState<string[]>([]);
    const [timeOptions, setTimeOptions] = useState<string[]>(optionArr)
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(currentUser => {
                let role: string = currentUser.getSignInUserSession()?.getIdToken().payload["cognito:groups"][0]
                setAdminNavLinks(role === 'Admin')

            })
            .catch(() => {
                console.log("Not signed in");

            });
        let inputBookId
        if (props.bookingData) inputBookId = props.bookingData.id
        // console.log("[booking] inputBookId:", inputBookId)
        raiseErrorOnInValidBookingDateTime(inputBookId)
    }, [props.bookingData])

    const BookingSchema = Yup.object().shape({
        bookingDate: Yup.string()
            .required("Booking date is required")
            .test('checkValidSchedule',
                'Sorry, this booking date/time is already taken.',
                // 'Sorry, this booking date/time is already taken.',
                function (item) {
                    let _tmpdate = moment(`${this.parent.bookingDate} ${this.parent.bookingStartTime}`, "YYYY-MM-DD hh:mm a")//.tz(timeZone)
                    const newBookingDate: Date = new Date(_tmpdate.format("YYYY-MM-DDTHH:mm:00"));
                    let decision = scheduleAppointment(appointments, newBookingDate, parseInt(this.parent.bookingLength))
                    setShowCalendarLink(!decision)
                    return decision
                }
            ),

        bookingStartTime: Yup.string()
            .required("Booking start time is required"),
        bookingLength: Yup.string()
            .required("Booking hour is required"),
        guestEmail: Yup.string().when('reserveUser', {
            is: 'guest',
            then: Yup.string().required("Enter guest email address")
        }),
        guestPhone: Yup.string().when('reserveUser', {
            is: 'guest',
            then: Yup.string().required("Enter guest phone")
        }),
        guestAddress: Yup.string().when('reserveUser', {
            is: 'guest',
            then: Yup.string().required("Enter guest street")
        }),
        guestCity: Yup.string().when('reserveUser', {
            is: 'guest',
            then: Yup.string().required("Enter guest city")
        }),
        pets: Yup.array()
            .of(
                Yup.object({
                    petName: Yup.string().required('Pet name required'),
                    petType: Yup.string().required('Pet type required'),
                })
            )
            .min(1, 'You need at least one pet'),
    });

    function raiseErrorOnInValidBookingDateTime(id: string | undefined = undefined) {
        getAllAppointmentsApi(id).then(setAppointments)
    }

    function adjustStartTime(nextDate: string, id: string | undefined = undefined) {
        getBookingsApi(id).then(bookings => {
            const removeIndexes: number[] = []
            let optionArrTemp: string[] = []
            skipDates = getSkippedTimes(bookings);
            if (skipDates[nextDate]) {
                optionArrTemp = optionArr.filter(o => !_.includes(skipDates[nextDate], o))
                for (let index = 0; index < optionArr.length; index++) {
                    const element = optionArr[index];
                    const elementNext = optionArr[index + 2];
                    if (elementNext) {
                        const d = moment(`2040-01-01 ${element}`, "YYYY-MM-DD hh:mm a")//.tz(timeZone);
                        const dNext = moment(`2040-01-01 ${elementNext}`, "YYYY-MM-DD hh:mm a")//.tz(timeZone);
                        if (dNext.diff(d, 'minutes') != 30) {
                            removeIndexes.push(index)
                        }
                    }
                }
                removeIndexes.forEach(o => optionArrTemp.splice(o, 1))
                setTimeOptions(optionArrTemp)
            } else {
                setTimeOptions(optionArr)
            }
        });
    }

    function getRepeatDuration(repeat: string) {
        let options: KeyValuePair[] = [];
        if (repeat === 'daily') {
            options = [
                { key: "1w", value: "1 Week" },
                { key: "2w", value: "2 Weeks" },
                { key: "3w", value: "3 Weeks" }
            ]
        } else if (repeat === 'weekly') {
            options = [
                { key: "2w", value: "2 Weeks" },
                { key: "3w", value: "3 Weeks" }
            ]
        }

        for (let o = 1; o < 13; o++) {
            options.push({ key: `${o}m`, value: `${o} Month(s)` })

        }
        return options
    }

    function getSkippedTimes(bookings: BookingData[]): any {
        //Not in use
        _.each(_.groupBy(bookings, 'bookingDate'), (v, element) => {
            let skipArr: string[] = [];
            v.forEach(b => {
                // let totalTime = bookingHoursMap[b.bookingEndTime] + 60;
                const d = moment(`${b.bookingDate} ${b.bookingStartTime}`, "YYYY-MM-DD hh:mm a")//.tz(timeZone);
                skipArr.push(d.format("LT"))
                for (let index = 0; index < 10; index = index + 15) {
                    let p = d.add(15, 'minutes').format("LT")
                    skipArr.push(p)
                }
            });
            skipDates[element] = skipArr;
        });
        return skipDates
    }
    function adjustCurrrentDayStartTime(selectedDate: any, values: any): any {
        let optionArrTemp: string[] = []
        if (selectedDate !== moment().format("YYYY-MM-DD")) {
            setTimeOptions(optionArr)
            return;
        } else if (selectedDate === moment().format("YYYY-MM-DD")) {
            for (let index = 0; index < optionArr.length; index++) {
                const element = optionArr[index];
                const d = moment(`${selectedDate} ${element}`, "YYYY-MM-DD hh:mm a");
                console.log(moment().diff(d, 'minutes'))
                if (moment().diff(d, 'minutes') < 0) optionArrTemp.push(element)
            }
            setTimeOptions(optionArrTemp)
            if (_.isEmpty(optionArrTemp)) {
                setFormBookingData({
                    ...values,
                    bookingStartTime: ''
                })
            } else {
                setFormBookingData({
                    ...values,
                    bookingStartTime: _.first(optionArrTemp)
                })
            }
        }
    }
    useEffect(() => {
        isUserLoggedIn().then(setIsLogin)
        return () => {
            console.log('This will be logged on unmount');
        };
    }, [authStatus])
    useEffect(() => {
        if (!props.bookingData) return
        // adjustStartTime(nextDate, props.bookingData.id)
        setFormBookingData(props.bookingData)
    }, [props.bookingData])

    const calculateBookingAmount = (values: BookingData): number => {
        let totalAmount: number = 0;
        let petCount: number = _.size(values.pets);
        const _bookingLength: keyof BookingData = 'bookingLength';
        const bookingLength = values[_bookingLength]
        if (values.serviceType == 'house-visit') {
            let allPetTypes: string[] | undefined = values.pets?.map(o => o.petType);
            let totalDogs: number | undefined = allPetTypes?.filter(o => o == "Dog").length;
            let totalCats: number | undefined = allPetTypes?.filter(o => o == "Cat").length;
            if (totalDogs) totalAmount += petRates['house-visit'][bookingLength]['Dog'][totalDogs.toString()];
            if (totalCats) totalAmount += petRates['house-visit'][bookingLength]['Cat'][totalCats.toString()];
        } else if (values.serviceType == 'dog-walking') {
            totalAmount = petRates['dog-walking'][bookingLength][petCount];
        }
        return totalAmount;
    }
    return (
        <>

            <Formik
                initialValues={formBookingData}
                validationSchema={BookingSchema}
                enableReinitialize={true}
                onSubmit={values => {
                    setShow(true);
                    let petNames = "";
                    values.bookingAmount = calculateBookingAmount(values);
                    let _endTime = moment(`${values.bookingDate} ${values.bookingStartTime}`, "YYYY-MM-DD hh:mm a").add(values.bookingLength, 'minutes').format("LT")
                    values.bookingEndTime = _endTime
                    petNames = _.chain(values.pets).map("petName").join(", ").value();
                    // console.log("petNames:", petNames)
                    addEditBookingApi(values, deletedPets)
                        .then((bookingId: string) => {
                            const messageHtml = renderEmail(
                                <BookingEmail fullName={values.reserveUser == "guest" ? "Guest" : loginUser}
                                    bookingLength={bookingHoursMap[values.bookingLength]} bookingDate={`${moment(values.bookingDate).format('MMM. DD, YYYY')}`} bookingStartTime={values.bookingStartTime} serviceType={values.serviceType == "dog-walking" ? "Dog Walking" : "House Visit"} petName={petNames} bookingId={bookingId} />
                            );
                            sendAppointmentEmailApi(messageHtml, "Booking confirmation with Lexi pet care");
                            // storeLocalData("bookingId", bookingId);
                            storeLocalData("paymentMethod", values.paymentMethod);
                            setShow(false);
                            if (values.reserveUser == "guest") {
                                if (values.paymentMethod == "online")
                                    props.history.push("/payment")
                                else props.history.push(`/booking-confirmation/${bookingId}`)
                            }
                            else {
                                if (!isLogin) props.history.push("/signin")
                                else if (values.paymentMethod == "online") props.history.push("/payment")
                                else props.history.push(`/booking-confirmation/${bookingId}`)
                            }
                        })
                        .catch(console.error)

                }}
            >
                {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                    <FormFormik>
                        <div>
                            {/* <div>{JSON.stringify(formBookingData)}</div> */}
                            <label className="mb-2 home-form-label">Enter the date and time you need pet care</label>
                            <div className="row">
                                <div className="col-lg-4">
                                    {/* <input type="text" id="datepicker" type="text" className="form-control" placeholder="Date" aria-label="Username" aria-describedby="basic-addon1" placeholder="date" /> */}
                                    <Field name="bookingDate"
                                    >
                                        {({ field, formProps }: any) => (
                                            <Form.Group controlId="bookingDate" className="mb-md-1 mb-3">
                                                <>
                                                    <div className="">
                                                        {/* <span className="input-group-text"></span> */}
                                                        <Form.Control type="date" min={moment(props.bookingData ? props.bookingData.bookingDate : new Date()).format("yyyy-MM-DD")} value={field.value} onChange={(event) => { field.onChange(event); adjustCurrrentDayStartTime(event.target.value, values) }} />
                                                    </div>
                                                    {!showCalendarLink && <ErrorMessage name="bookingDate" component="div" className={"error"} />}
                                                    <Modal show={showEventCalendarPopup} size="lg" onHide={() => setShowEventCalendarPopup(false)}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Reserved Slots</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <EventCalendarPopup currentDate={field.value} />
                                                        </Modal.Body>
                                                    </Modal>

                                                </>
                                            </Form.Group>
                                        )}
                                    </Field>
                                </div>

                                <div className="col-lg-4">

                                    <Field
                                        name={`bookingStartTime`}
                                    >{({ field, formProps }: any) => (
                                        <Form.Group controlId={`bookingStartTime`} className="mb-md-1 mb-3">
                                            <>
                                                <div className="input-group home-banner-start-time">
                                                    <span className="input-group-text"></span>
                                                    <Form.Select value={field.value} onChange={field.onChange} className="form-select" aria-label="Default select example">
                                                        <option value="">Start Time</option>
                                                        {timeOptions.map(o => <option value={o}>{o}</option>)}
                                                    </Form.Select>
                                                </div>
                                                <ErrorMessage name={`bookingStartTime`} component="div" className={"error"} />
                                            </>
                                        </Form.Group>
                                    )}</Field>




                                </div>
                                <div className="col-lg-4">
                                    <Field
                                        name={`bookingLength`}
                                    >{({ field, formProps }: any) => (
                                        <Form.Group controlId={`bookingLength`} className="mb-md-1 mb-3">
                                            <div className="input-group home-banner-number-hour">
                                                <span className="input-group-text"></span>
                                                {/* <Form.Select value={field.value} onChange={field.onChange} className="form-select" aria-label="Default select example">
                                                <option value="">End Time</option>
                                                {optionArr.map(o => <option value={o}>{o}</option>)}
                                            </Form.Select> */}
                                                <Form.Select value={field.value} onChange={field.onChange} className="form-select" aria-label="Default select example">
                                                    <option value="">No. of hours</option>
                                                    <option value="30">30 Min</option>
                                                    <option value="60">1 Hour</option>
                                                    <option value="120">2 Hours</option>
                                                    <option value="180">3 Hours</option>
                                                </Form.Select>
                                            </div>
                                            <ErrorMessage name={`bookingLength`} component="div" className={"error"} />
                                        </Form.Group>
                                    )}</Field>
                                </div>
                            </div>

                            <div className="row mb-2">{showCalendarLink && errors.bookingDate && <div className={"error"}>{errors.bookingDate}<a href="#" onClick={() => setShowEventCalendarPopup(true)}>View Calendar</a></div>}</div>
                            <div className="row">
                                <Row style={{ width: "100%" }}>
                                    <Col>
                                        <Field type="radio" size={"small"} name="serviceType" value="dog-walking" /> Dog Walking
                                    </Col>
                                    <Col>
                                        <Field type="radio" size={"small"} name="serviceType" value="house-visit" /> House Visit
                                    </Col>
                                </Row>
                            </div>
                            <FieldArray
                                name="pets"
                                render={arrayHelpers => (
                                    <>
                                        {values.pets?.map((pet: any, index: number) => (
                                            <div key={index}>
                                                <Row style={{}}>
                                                    <Col><label className="mb-2 home-form-label">Pet {(index !== 0 && _.size(values.pets) >= 2) ? `#${index + 1}` : ''} Information</label></Col>
                                                    <Col style={{ display: 'flex', justifyContent: 'right', borderWidth: 1 }}>
                                                        {(index !== 0 && _.size(values.pets) >= 2) ? <CloseButton variant="red" onClick={() => {
                                                            if (formBookingData.id && values.pets && values.pets[index].id && index >= 0) {
                                                                deletedPets.push(values.pets[index].id || "")
                                                                setDeletedPets(deletedPets)
                                                            }
                                                            arrayHelpers.remove(index)
                                                        }} /> : null}
                                                    </Col>
                                                </Row>
                                                <Row style={{}}>
                                                    {values.serviceType == 'house-visit' ? <Col>
                                                        <Field
                                                            name={`pets[${index}].petType`}
                                                        >{({ field, formProps }: any) => (
                                                            <Form.Group controlId={`pets[${index}].petType`} className="mb-3">
                                                                <div className="input-group home-banner-type-pet">
                                                                    <span className="input-group-text"></span>
                                                                    <Form.Select value={field.value} onChange={field.onChange}>
                                                                        <option value="">Type of pet</option>
                                                                        <option value="Dog">Dog</option>
                                                                        <option value="Cat">Cat</option>
                                                                    </Form.Select>
                                                                </div>
                                                                <ErrorMessage name={`pets[${index}].petType`} component="div" className={"error"} />
                                                            </Form.Group>
                                                        )}</Field>
                                                    </Col> : <Field
                                                        name={`pets[${index}].petType`}
                                                    >{({ field, formProps }: any) => (
                                                        <Form.Group controlId={`pets[${index}].petType`} className="">
                                                            <Form.Control type={'hidden'} value={"Dog"} onChange={field.onChange} /></Form.Group>)}</Field>}
                                                    <Col>
                                                        <Field
                                                            name={`pets[${index}].petName`}
                                                        >{({ field, formProps }: any) => (
                                                            <Form.Group controlId={`pets[${index}].petName`} className="mb-3">
                                                                <div className="input-group home-banner-pet-name">
                                                                    <span className="input-group-text"></span>
                                                                    <Form.Control type={'text'} placeholder="My pet's name" value={field.value} onChange={field.onChange} />

                                                                </div>
                                                                <ErrorMessage name={`pets[${index}].petName`} component="div" className={"error"} />
                                                            </Form.Group>
                                                        )}
                                                        </Field>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Field
                                                            name={`pets[${index}].petNeeds`}
                                                        >{({ field, formProps }: any) => (
                                                            <Form.Group controlId={`pets[${index}].petNeeds`} className="mb-3">
                                                                <div className="input-group home-banner-pet-needs">
                                                                    <span className="input-group-text"></span>
                                                                    <Form.Control type={'text'} placeholder="Special needs for my pet" value={field.value} onChange={field.onChange} />
                                                                </div>
                                                            </Form.Group>
                                                        )}</Field>

                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                        {_.size(arrayHelpers.form.values.pets) <= 2 && <div><Alert.Link href="#" onClick={() => {
                                            arrayHelpers.push({ petName: '', petType: arrayHelpers.form.values.serviceType == "dog-walking" ? 'Dog' : '', petNeeds: '' })
                                            // adjustQuoate(arrayHelpers.form.values)
                                        }}>Click here</Alert.Link> if you have more pets needing care at the same date and time.</div>}

                                    </>
                                )}
                            />
                            <div className="home-banner-instruction">

                                <p>No payment required at this time. You can pay for pet services after they
                                    are completed.
                                </p>
                            </div>
                            <div className="row">
                                <label className="mb-2 home-form-label">Reserve this date and time</label>
                                <div className="col-lg-4">

                                    <Field
                                        name="repeat"
                                    >{({ field, formProps }: any) => (
                                        <Form.Group controlId={`repeat`} className="mb-3">
                                            <div className="input-group home-banner-date">
                                                <span className="input-group-text"></span><Form.Select value={field.value} onChange={field.onChange}>
                                                    <option value="none">None</option>
                                                    <option value="daily">Daily</option>
                                                    <option value="weekly">Weekly</option>
                                                    <option value="monthly">Monthly</option>
                                                </Form.Select></div>
                                            <ErrorMessage name={`repeat`} component="div" className={"error"} />
                                        </Form.Group>
                                    )}</Field>

                                </div>
                                {values.repeat !== "none" ? <>
                                    For Next
                                    <div className="col-lg-4">
                                        <Field
                                            name="repeatDuration"
                                        >{({ field, formProps }: any) => (
                                            <Form.Group controlId={`repeatDuration`} className="mb-3">
                                                <div className="input-group home-banner-date">
                                                    <span className="input-group-text"></span>
                                                    <Form.Select value={field.value} onChange={field.onChange}>
                                                        <option value="">None</option>
                                                        {getRepeatDuration(values.repeat).map(o => <option value={o.key}>{`${o.value}`}</option>)}
                                                    </Form.Select></div>
                                                <ErrorMessage name={`repeatDuration`} component="div" className={"error"} />
                                            </Form.Group>
                                        )}</Field>

                                    </div>
                                </> : null}
                            </div>
                            <div className="row mt-2">
                                {(!isLogin || formBookingData.id) ? <div className="mb-3">

                                    {adminNavLinks || !isLogin ? <Row style={{ width: "100%" }}>
                                        <Col>
                                            <Field type="radio" size={"small"} name="reserveUser" value="guest" /> Continue as a guest
                                        </Col>
                                        <Col>
                                            <Field type="radio" size={"small"} name="reserveUser" value="account" /> Create an account or login to reserve this date and time.
                                        </Col>
                                    </Row> : null}
                                    {values.reserveUser == "guest" &&
                                        <>
                                            <Row style={{ width: "100%" }}>
                                                <Col>
                                                    <Field
                                                        name={`guestEmail`}
                                                    >{({ field, formProps }: any) => (
                                                        <Form.Group controlId={`guestEmail`} className="mb-2">
                                                            <Form.Control type={'email'} placeholder="Please enter your email (required)" value={field.value} onChange={field.onChange} />
                                                            <ErrorMessage name={`guestEmail`} component="div" className={"error"} />
                                                        </Form.Group>
                                                    )}
                                                    </Field>
                                                </Col>
                                                <Col>
                                                    <Field
                                                        name={`guestPhone`}
                                                    >{({ field, formProps }: any) => (
                                                        <Form.Group controlId={`guestPhone`} className="mb-2">
                                                            {/* <Form.Control type={'tel'} placeholder="Please enter your phone (required)" value={field.value} onChange={field.onChange} /> */}
                                                            <PhoneNumberMask
                                                                id="guestPhone"
                                                                name="guestPhone"
                                                                type="tel"
                                                                placeholder="Please enter your phone (required)"
                                                                field={field}
                                                                setFieldValue={setFieldValue}
                                                            />
                                                            <ErrorMessage name={`guestPhone`} component="div" className={"error"} />
                                                        </Form.Group>
                                                    )}</Field>
                                                </Col>
                                            </Row><Row style={{ width: "100%" }}>
                                                <Col>
                                                    <Field
                                                        name={`guestAddress`}
                                                    >{({ field, formProps }: any) => (
                                                        <Form.Group controlId={`guestAddress`} className="mb-2">
                                                            <Form.Control type={'text'} placeholder="Please enter your street (required)" value={field.value} onChange={field.onChange} />
                                                            <ErrorMessage name={`guestAddress`} component="div" className={"error"} />
                                                        </Form.Group>
                                                    )}
                                                    </Field>
                                                </Col>

                                                <Col>
                                                    <Field
                                                        name={`guestCity`}
                                                    >{({ field, formProps }: any) => (
                                                        <Form.Group controlId={`guestCity`} className="mb-2">
                                                            <Form.Control type={'text'} placeholder="Please enter your city (required)" value={field.value} onChange={field.onChange} />
                                                            <ErrorMessage name={`guestCity`} component="div" className={"error"} />
                                                        </Form.Group>
                                                    )}
                                                    </Field>
                                                </Col>

                                            </Row>
                                        </>
                                    }

                                </div> : null}
                            </div>
                            {/* <div> {JSON.stringify(values)}</div> */}
                            <div className="text-center mt-3">
                                <button className={show ? "btn btn-light" : "book-date-btn btn--1"} disabled={show ? true : false}>
                                    {show ?
                                        <RiLoader5Line className='mr-2' style={{ color: '#92cff7' }} /> : null
                                    }
                                    {show ? <span> Submitting request...</span> : <span>Reserve this date and time</span>}  </button>
                                {props.bookingData && props.bookingData.id ? <>&nbsp;<button type='button' className="btn btn-secondary book-date-cancel-btn" onClick={() => props.history.push('/my-bookings')}>Cancel</button></> : null}
                            </div>
                        </div>
                    </FormFormik>
                )}
            </Formik>

        </>
    );
};

export default Book;