import { Button } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SiteSpinner from "../../components/UI/Spinner"
import { updateMyBookingsApi } from '../../services';
import { getLocalData } from '../../services/global-storage';

const FailedPayment = props => {
    return <div>
        <h1>Something went wrong with your payment</h1>
        <Button variation='primary' onClick={()=>props.history.push("/payment")}>Try Again</Button>
    </div>;

}

export default FailedPayment;