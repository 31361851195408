import React from 'react';
import { useFormik, FormikProps } from 'formik';

interface FormValues {
  phoneNumber: string;
}

const initialValues: FormValues = {
  phoneNumber: ''
};

interface PhoneNumberMaskProps {
  field: any;
  id: any;
  name: any;
  type: any;
  form: FormikProps<FormValues>;
}

const PhoneNumberMask: React.FC<PhoneNumberMaskProps> = ({ field, form, ...props }) => {
  const { setFieldValue } = form;
  const { value } = field;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    let newValue = value.replace(/\D/g, '');
    if (newValue.length > 10) {
      newValue = newValue.slice(0, 10);
    }
    if (newValue.length > 6) {
      newValue = `(${newValue.slice(0, 3)})-${newValue.slice(3, 6)}-${newValue.slice(6)}`;
    } else if (newValue.length > 3) {
      newValue = `(${newValue.slice(0, 3)})-${newValue.slice(3)}`;
    }
    setFieldValue(field.name, newValue);
  };

  const handleBlur = () => {
    let newValue = value;
    if (newValue.length === 10) {
      newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(3, 6)}-${newValue.slice(6)}`;
    }
    setFieldValue(field.name, newValue);
  };

  return (
    <input
      {...field}
      {...props}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

const PhoneNumberForm: React.FC = () => {
  const formik = useFormik<FormValues>({
    initialValues,
    onSubmit: (values) => {
      console.log(values);
    }
  });

  return (
    <div className="container">
      <div className="memeber-area min-height-800">
        <form onSubmit={formik.handleSubmit}>
          <label htmlFor="phoneNumber">Phone Number:</label>
          <PhoneNumberMask
            id="phoneNumber"
            name="phoneNumber"
            type="text"
            field={formik.getFieldProps('phoneNumber')}
            form={formik}
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default PhoneNumberForm;
