import _ from 'lodash';
import { updateObject } from '../utility'
import * as actionTypes from '../actions/actionTypes';

const initialState = {};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_LOCAL_DATA:
            return updateObject(state, action.localData);
        case actionTypes.REMOVE_LOCAL_DATA: {
            let newState = { ...state };
            delete newState[action.key];
            return newState;
        }
        case actionTypes.CLEAR_LOCAL_DATA: {
            let newState = { ...state };
            _.keys(newState).forEach(k => delete newState[k])
            return newState;
        }
        default:
            return state;
    }
};

export default reducer;
