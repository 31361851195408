import React, { useEffect, useState } from 'react';
import Book from '../Home/book';
import { Route, Switch, withRouter, Redirect, useParams } from 'react-router-dom';
import { getBookingByIdApi } from '../../services';
import { BookingData } from '../../interfaces';
import ProtectedArea from '../../hoc/protected-area';
import _ from 'lodash';
type SearchParams = {
    id: string;
}
const Reschedule = (props: any) => {
    const { id } = useParams<SearchParams>();
    const [bookingData, setBookingData] = useState<BookingData>()
    useEffect(() => {
        getBookingByIdApi(id).then((response: BookingData) => {
            response = _.omit(response, ['ownerDetail'])
            console.log("[booking] response.data.getBooking: ", response)
            setBookingData(response)
        })
    }, [])
    return (
        <ProtectedArea>
            <div className="container mb-90">
                <div className="row justify-content-center mx-0">
                    <div className="col-lg-7 reschedule-form pt-2 pt-lg-4 pb-3 px-lg-4">
                        <h2 className="text-center fontw-600"> <span className="text-theme1">RESCHEDULE/EDIT</span> <span className="text-dark">BOOKING</span></h2>
                        <Book {...props} bookingData={bookingData} />
                    </div>
                    <div style={{height:100}}></div>
                </div>
            </div>
        </ProtectedArea>
    );
};
export default Reschedule;