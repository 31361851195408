import React, { Component, useEffect, useState } from 'react';
import { Route, Switch, withRouter, Redirect, useParams } from 'react-router-dom';
import Layout from './hoc/Layout/Layout';
import Home from './containers/Home';
import About from './containers/About';
import Rates from './containers/Rates';
import Book from './containers/Book';
import Reschedule from './containers/Book/reschedule';
import Policy from './containers/Policy';
import Member from './containers/Member';
import Contact from './containers/Contact';
import Users from './containers/Admin/users';
import BookingConfirmation from './containers/Book/booking-confirmation';
import Login from './containers/Member/login';
import MyBookings from './containers/Book/my-bookings';
import AllBookings from './containers/Admin/all-bookings';
import PhoneNumberForm from './containers/Test/phone-mask';
import Pagination from './containers/Test/pagination';
import Blogs from './containers/Test/blogs';
import EventCalendar from './containers/Admin/event-calendar';
import AllUnavailability from './containers/Admin/all-unavailability';
import EditProfile from './containers/Member/edit-profile'
import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { clearLocalData, getLocalData, removeLocalData, storeLocalData } from './services/global-storage';
import Payment from './containers/Payment';
import Completion from './containers/Payment/completion';
import FailedPayment from './containers/Payment/failed-payment';
import AllQueries from './containers/Admin/all-queries';
import ChangePassword from './containers/Member/change-password'
import ChangeEmail from './containers/Member/change-email'
import { createBookingApi } from './services';
import _ from 'lodash';
const App = props => {
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);
  async function signEvent() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      let role = _.first(user.getSignInUserSession()?.getIdToken().payload["cognito:groups"])
      if (!user.attributes.address) {
        props.history.push("/edit-profile/y")
      } else if (getLocalData("paymentMethod") == "online" && getLocalData("bookingId")) {
        props.history.push("/payment")
      } else if (getLocalData("paymentMethod") == "offline" && getLocalData("lastBookingType") == 'Draft') {
        let bookingData = JSON.parse(getLocalData("lastBookingData"))
        let allPets = bookingData.pets;
        delete bookingData.pets;
        let bookingId = await createBookingApi(bookingData, allPets);
        removeLocalData('lastBookingData')
        removeLocalData('lastBookingType')
        props.history.push(`/booking-confirmation/${bookingId}`);
      } else {
        if (getLocalData("redirectPath")) {
          let redirectPath = getLocalData("redirectPath")
          removeLocalData('redirectPath')
          props.history.push(redirectPath)
        }
        else {
          if (role === 'Admin') {
            props.history.push("/all-bookings")
          } else {
            props.history.push("/my-bookings")
          }
        }

      }
    } catch (error) {
      console.error(error)
    }

  }

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          signEvent()
          break;
        case "signOut":
          clearLocalData()
          setUser(null);
          props.history.push("/signin")
          break;
        case "customOAuthState":
          setCustomState(data);
      }
    });

    Auth.currentAuthenticatedUser()
      .then(currentUser => {
        console.log("OAUTH:currentAuthenticatedUser", currentUser.attributes.sub)
        setUser(currentUser)
      })
      .catch(() => {
        console.log("Not signed in");
        storeLocalData('isLoggedIn', false)
      });

    return unsubscribe;
  }, []);
  let routes = (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/about" exact component={About} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/policy" exact component={Policy} />
      <Route path="/service-rate" exact component={Rates} />
      <Route path="/book" exact component={Book} />
      <Route path="/signin" exact component={Login} />
      {/* <Route path="/member-area" exact component={Member} /> */}
      <Route path="/my-bookings" exact component={MyBookings} />
      <Route path="/all-bookings" exact component={AllBookings} />
      <Route path="/all-users" exact component={Users} />
      <Route path="/all-queries" exact component={AllQueries} />
      <Route path="/all-unavailable" exact component={AllUnavailability} />
      <Route path="/change-email" exact component={ChangeEmail} />
      <Route path="/test/phone-mask" exact component={PhoneNumberForm} />
      <Route path="/test/pagination" exact component={Pagination} />
      <Route path="/test/blogs" exact component={Blogs} />
      <Route path="/booking-confirmation/:bookingId" exact component={BookingConfirmation} />
      <Route path="/payment" exact component={Payment} />
      <Route path="/complete-payment" exact component={Completion} />
      <Route path="/failed-payment" exact component={FailedPayment} />
      <Route path="/event-calendar" exact component={EventCalendar} />
      <Route path="/reschedule/:id" exact component={Reschedule} />
      <Route path="/change-password" exact component={ChangePassword} />
      <Route path="/edit-profile/:sw?" exact component={EditProfile} />
      <Redirect to="/" />
    </Switch>
  );
  return (
    <div className="main-page-inner">
      <Layout {...props}>
        {routes}
      </Layout>
    </div>
  );

}


export default withRouter(App);
