
import React, { useEffect, useState } from 'react';
import ProtectedArea from '../../hoc/protected-area';
import { AccountSettings, Card, ThemeProvider } from '@aws-amplify/ui-react';
import { Toast, ToastContainer } from 'react-bootstrap';
import ConfirmationToast from '../../components/UI/ConfirmationToast';
const ChangePassword = () => {
    // const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    // const [showError, setShowError] = useState<boolean>(false)
    const [toastData, setToastData] = useState<any>({
        title: '',
        desciption: '',
        type: '',
        showToast: false
    })
    const toggleConfirmation = () => setToastData({ ...toastData, showToast: !toastData.showToast });

    // const toggleError = () => setShowError(!showError);
    const theme = {
        name: 'custom-theme',
        tokens: {
            colors: {
                border: {
                    primary: { value: '{colors.neutral.40}' },
                    secondary: { value: '{colors.neutral.20}' },
                    tertiary: { value: '{colors.neutral.10}' },
                },
            },
            radii: {
                small: { value: '2px' },
                medium: { value: '3px' },
                large: { value: '4px' },
                xl: { value: '6px' },
            },
        },
    };
    return (
        <>
            <ProtectedArea>
                <div className="container  mb-90">
                    <div className="row justify-content-center edit-profile-title change-password-block">
                        <h2 className="text-center text-theme1">CHANGE <span className='text-dark'>PASSWORD</span></h2>
                        <ThemeProvider theme={theme}>
                            <Card width="100%">
                                <AccountSettings.ChangePassword
                                    onSuccess={() => {
                                        setToastData({
                                            title: 'Change Password',
                                            description: 'Your profile password has been changed successfully.',
                                            type: 'success',
                                            showToast: true
                                        });
                                    }}
                                    onError={() => {
                                        setToastData({
                                            title: 'Error',
                                            description: 'Something went wrong! Please try again.',
                                            type: 'danger',
                                            showToast: true
                                        });
                                    }}
                                />
                            </Card>
                        </ThemeProvider>
                    </div>
                </div>
            </ProtectedArea>
            <ConfirmationToast
                title={toastData.title}
                description={toastData.description}
                type={toastData.type}
                showToast={toastData.showToast}
                toggleConfirmation={toggleConfirmation}
            />
            {/* <ToastContainer position="top-end" className="p-3">
           <Toast show={showConfirmation}
               onClose={toggleConfirmation}
               bg={'success'}
           >
               <Toast.Header>
                   <strong className="me-auto">Change Password</strong>
               </Toast.Header>
               <Toast.Body>Your password successfully done !</Toast.Body>
           </Toast>
       </ToastContainer>
       <ToastContainer position="top-end" className="p-3">
                <Toast onClose={toggleError}
                    show={showError}
                    animation={false}
                    bg={'danger'}>
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body className={'text-white'}>Something went wrong! Please try again..</Toast.Body>
                </Toast>
            </ToastContainer> */}
        </>
    );

}

export default ChangePassword;
