import React, { useState, useEffect } from 'react'
import { API, graphqlOperation, GraphQLResult } from '@aws-amplify/api'
import { listBlogs } from '../../graphql/queries'
import { Blog } from '../../interfaces'

function Paginate(props: any) {
  let { isLoading, hasNext, hasPrev, next, prev } = props
  const disabledPrev = !hasPrev || isLoading
  const disabledNext = !hasNext || isLoading
  return (
    <div className="flex justify-between px-4 py-2 mb-4 text-sm bg-white rounded shadow-md lg:py-4 lg:px-8">
      <button
        disabled={disabledPrev}
        onClick={prev}
      >
        <span>Previous</span>
      </button>
      <button
        disabled={disabledNext}
        onClick={next}
      >
        <span>Next</span>

      </button>
    </div>
  )
}

function Pagination() {
  const [isLoading, setIsLoading] = useState(false)
  const [nextToken, setNextToken] = useState(undefined)
  const [nextNextToken, setNextNextToken] = useState<any>()
  const [previousTokens, setPreviousTokens] = useState<any[]>([])
  const [blogs, setBlogs] = useState([])
  const hasNext = !!nextNextToken
  const hasPrev = previousTokens.length
  const limit = 5

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      try {
        const result = (await API.graphql(graphqlOperation(listBlogs, {
          nextToken,
          limit,
        }))) as GraphQLResult<any>;
        setNextNextToken(result.data.listBlogs.nextToken)
        setBlogs(result.data.listBlogs.items)
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }
    fetch()
  }, [nextToken])

  const next = () => {
    setPreviousTokens((prev: any[]) => [...prev, nextToken])
    setNextToken(nextNextToken)
    setNextNextToken(null)
  }

  const prev = () => {
    setNextToken(previousTokens.pop())
    setPreviousTokens([...previousTokens])
    setNextNextToken(null)
  }


  return (
    <div className="container min-height-500">
      <div className="row justify-content-center">
        <Paginate {...{ hasNext, hasPrev, prev, next, isLoading }} />
        <div>
          {blogs.map((blog: Blog, i) => (
          <div>
              <div>
                <span>
                  {blog.title}
                </span>
              </div>
              <div>
                <span className="block">{blog.content}</span>
              </div>
              <hr />
            </div>
        ))}
      </div>
    </div>
    </div>
  )
}

export default Pagination