import  store  from '../store';
import _ from 'lodash';


const removeItem = (key: string) => {
  // SyncStorage.remove(key);
};

const hasUserAlreadyLoggedIn = () => {
  //FixMe#1: accessToken should be checked for login state
  return !_.isEmpty(getLocalData('accessToken'));
};

const storeCachedData = (key: string, value: string) => {
  let keyValObject: any = {};
  keyValObject[`${key}`] = value;
  store.dispatch({ type: 'STORE_CACHE_DATA', cacheData: keyValObject });
};

const storeLocalData = (key: string, value: string) => {
  let keyValObject: any = {};
  keyValObject[`${key}`] = value;
  store.dispatch({ type: 'STORE_LOCAL_DATA', localData: keyValObject });
};

const removeLocalData = (key: string) => {
  store.dispatch({ type: 'REMOVE_LOCAL_DATA', key: key });
};

const clearLocalData = (key: string) => {
  store.dispatch({ type: 'CLEAR_LOCAL_DATA', key: key });
};

const clearCachedData = (key: string) => {
  store.dispatch({ type: 'CLEAR_CACHE_DATA', key: key });
};

const getLocalData = (key: string) => {
  return _.get(store.getState(), ['localData', key]);
};

const getAccessToken = async () => {
  return await _.get(store.getState(), ['localData', 'accessToken']);
};
const getCachedData = (key: string) => {
  return _.get(store.getState(), ['cacheData', key]);
};
export {
  removeItem,
  hasUserAlreadyLoggedIn,
  clearLocalData,
  storeCachedData,
  storeLocalData,
  getLocalData,
  removeLocalData,
  getAccessToken,
  getCachedData,
  clearCachedData
};
