import { Button, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import _ from 'lodash';
import React from 'react';
import { ContactData } from '../../interfaces';
const QueriesItem = (props: any) => {
    return (
        <Table
            className='all-user-table table-responsive'
            caption=""
            highlightOnHover={false}>
            <TableHead>
                <TableRow>
                    <TableCell as="th">Subject</TableCell>
                    <TableCell as="th">Name</TableCell>
                    <TableCell as="th">Email</TableCell>
                    <TableCell as="th">Phone</TableCell>
                    <TableCell as="th">Query Status</TableCell>
                    <TableCell as="th">Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.queriesList.map((o: ContactData, i: number) => <TableRow key={i}>
                <TableCell className='d-none-md width-50-sm font-weight-bold'>Subject</TableCell>
                    <TableCell>{o.subject}</TableCell>
                    <TableCell className='d-none-md width-50-sm font-weight-bold'>Name</TableCell>
                    <TableCell>{o.name}</TableCell>
                    <TableCell className='d-none-md width-50-sm font-weight-bold'>Email</TableCell>
                    <TableCell>{o.email}</TableCell>
                    <TableCell className='d-none-md width-50-sm font-weight-bold'>Phone</TableCell>
                    <TableCell>{o.phone}</TableCell>
                    <TableCell className='d-none-md width-50-sm font-weight-bold'>Query Status</TableCell>
                    <TableCell>{o.queryStatus}</TableCell>
                    <TableCell>
                        <Button
                            fontWeight="normal"
                            size="small"
                            variation="link"
                            onClick={() => props.updateQueryStatus(o.id, o.queryStatus=="Resolved"?"Pending":"Resolved")}
                            >{o.queryStatus=="Resolved"?"Pending":"Resolved"}</Button>

                        <Button
                            fontWeight="normal"
                            size="small"
                            variation="link"
                            onClick={() => props.deleteContact(o.id)}
                            >Delete</Button>
                  </TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    )
};
export default QueriesItem