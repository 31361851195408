import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Modal, Button } from 'react-bootstrap';
import { Appointment, BookingData } from '../../interfaces';
import { createUnavailablityApi, deleteUnavailabityApi, getAllAppointmentsApi, getBookingsApi } from '../../services';
import _ from 'lodash';
import config from '../../services/config';
import SiteSpinner from '../../components/UI/Spinner';

const localizer = momentLocalizer(moment);

interface Event {
  id: number;
  title: string;
  description1: string;
  description2: string;
  start: Date;
  end: Date;
}

interface Props {
  events: Event[];
}

const UnavailabilityCalendar: React.FC<Props> = (props) => {
  const clickRef = useRef(null)
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const [showClicked, setShowClicked] = useState(false);
  const [bookEvents, setBookEvents] = useState<Appointment[]>();
  const [clickedEvent, setClickedEvent] = useState<Appointment>();
  const [isLoading, setIsLoading] = useState(true)
  const handleClose = () => setShow(false);
  const handleClickedClose = () => setShowClicked(false);
  const handleShow = () => setShow(true);
  const [selectedDateRange, setSelectedDateRange] = useState<{
    start: Date;
    end: Date;
    id: string;
  } | null>(null);

  const handleSelectSlot = (slotInfo: { start: Date; end: Date, id: string }) => {
    handleShow();
    setSelectedDateRange({ start: slotInfo.start, end: slotInfo.end, id: slotInfo.id });
  };

  const handleMarkUnavailable = async () => {
    if (selectedDateRange) {
      // Perform logic to mark the selected date/time range as unavailable here
      try {
        console.log(selectedDateRange.start)
        let response = await createUnavailablityApi(selectedDateRange.start, selectedDateRange.end);
        // alert("marked unavailable hours");
        handleClose()
        setReload(!reload)
        console.log("[Event Calendar] created unavailbility: ", response)
      } catch (error) {
        console.error(error)
      }
    }
  };

  useEffect(() => {
    setIsLoading(true)
    getAllAppointmentsApi().then(r => {
      console.log("[Event Calendar] getAllAppointmentsApi:", r)
      setBookEvents(r)
      setIsLoading(false)
    }).catch(e => setIsLoading(false))
  }, [reload])

  const deleteHandler = async (clickedEvent: Appointment) => {
    setIsLoading(true)
    handleClickedClose();
    await deleteUnavailabityApi(clickedEvent.id);
    setIsLoading(false)
    setReload(!reload)
  }
  const onSelectEvent = useCallback((calEvent: Appointment) => {
    /**
     * Here we are waiting 250 milliseconds (use what you want) prior to firing
     * our method. Why? Because both 'click' and 'doubleClick'
     * would fire, in the event of a 'doubleClick'. By doing
     * this, the 'click' handler is overridden by the 'doubleClick'
     * action.
     */
    window.setTimeout(() => {
      setClickedEvent(calEvent);
      setShowClicked(true)
    }, 250)
  }, [])



  return (
    <>
      <div className="container">
        <div className=" text-center">
          <h2 className="text-center fontw-600"> <span className="text-theme1">ALL</span> <span className="text-dark">BOOKINGS CALENDAR</span>   </h2>

        </div></div>
      <div className="container mt-lg-4 mt-3 mb-4">
        {isLoading ? <SiteSpinner /> : null}
        {!isLoading ? <div className="row justify-content-center">
          <Calendar
            localizer={localizer}
            events={bookEvents}
            defaultDate={new Date()}
            onSelectEvent={onSelectEvent}
            selectable
            onSelectSlot={handleSelectSlot}
            defaultView={'week'}
            min={new Date(1972, 0, 1, 8, 0, 0, 0)}
            max={new Date(1972, 0, 1, 20, 0, 0, 0)}
          />
          {selectedDateRange && (
            <div
              className="modal show"
              style={{ display: 'block', position: 'initial' }}
            >
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Mark as Unavailable</Modal.Title>
                </Modal.Header>
                <Modal.Body><p>
                  {moment(selectedDateRange.start).format(config.dateLongFormat)} - {' '}
                  {moment(selectedDateRange.end).format(config.dateLongFormat)}
                </p></Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button onClick={handleMarkUnavailable}>Confirm</Button>
                </Modal.Footer>
              </Modal>


            </div>
          )}
          {clickedEvent && (
            <div
              className="modal show"
              style={{ display: 'block', position: 'initial' }}
            >
              <Modal show={showClicked} onHide={handleClickedClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Event Detail View</Modal.Title>
                </Modal.Header>
                <Modal.Body><p>
                  {moment(clickedEvent.start).format(config.dateLongFormat)} - {' '}
                  {moment(clickedEvent.end).format(config.dateLongFormat)} <br /><br />
                  {clickedEvent.title}<br />
                  {clickedEvent.description1}<br />
                  {clickedEvent.description2}
                </p></Modal.Body>
                <Modal.Footer>
                  {clickedEvent.type === 'unavailable' ? <Button onClick={() => deleteHandler(clickedEvent)}>Delete</Button> : null}
                  <Button variant="secondary" onClick={handleClickedClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>


            </div>
          )}
        </div> : null}
      </div>

    </>
  );
};



export default UnavailabilityCalendar;
