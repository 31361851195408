import { Button, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import SiteSpinner from '../../components/UI/Spinner';
import ProtectedArea from '../../hoc/protected-area';
import { BookingData } from '../../interfaces';
import { Toast, ToastContainer, Modal } from 'react-bootstrap';
import { getBookingsApi, updateBookingsApi, deleteBookingsApi, getLoggedInUserId, updateBookingSeriesApi } from '../../services';
import BookingItems from './booking-items';
import ConfirmationModal from '../../components/UI/ConfirmationModal';
import ConfirmationToast from '../../components/UI/ConfirmationToast';
import RecordNotFound from '../../components/UI/RecordNotFound';
const MyBookings = (props: any) => {
    const [apiError, serApiError] = useState(false);
    const [bookingId, setBookingId] = useState<any>();
    const [parentSeriesId, setParentSeriesId] = useState<any>()
    const [reload, setReload] = useState(false);
    const [bookingStatus, setBookingStatus] = useState<any>()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showCancel, setShowCancel] = useState(false);
    const handleCancelClose = () => setShowCancel(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [toastData, setToastData] = useState<any>({
        title: '',
        desciption: '',
        type: '',
        showToast: false
    })
    const toggleConfirmation = () => setToastData({ ...toastData, showToast: !toastData.showToast });
    const toggleError = () => setShowError(!showError);
    const [message, setMessage] = useState("");
    const [title, settitle] = useState("");
    const [isLoading, setIsLoading] = useState(true)
    const [bookings, setBookings] = useState<BookingData[]>([])
    useEffect(() => {
        fetchMyBookings()
    }, [showConfirmation, reload])
    async function fetchMyBookings() {
        try {
            setIsLoading(true)
            // let currentUser = await getLoggedInUserId()
            const response = await getBookingsApi(undefined, 'Active', undefined, true);
            setIsLoading(false)
            setReload(false)
            setBookings(response)
        } catch (err) {
            serApiError(true);
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    };
    async function cancelBookings(id: string | undefined, bookingStatus: string, parentSeriesId: string) {
        await updateBookingsApi({id, bookingStatus});
        // setShowConfirmation(true);
        setShowCancel(false);
        setToastData({
            title: 'Cancelled successfully',
            description: 'Cancel booking request successfully completed.',
            type: 'success',
            showToast: true
        });
        setReload(true)
    };
    async function cancelBookingAll(id: string | undefined, bookingStatus: string, parentSeriesId: string) {
        await updateBookingSeriesApi(id, bookingStatus, parentSeriesId);
        // setShowConfirmation(true);
        setShowCancel(false);
        setToastData({
            title: 'Cancelled successfully',
            description: 'Cancel booking series request successfully completed.',
            type: 'success',
            showToast: true
        });
        setReload(true)
    };
    function deleteHandler(id: string) {
        setBookingId(id);
        setShow(true);

    }
    function cancelHandler(id: string | undefined, bookingStatus: string, parentSeriesId: string) {
        setBookingId(id);
        setBookingStatus(bookingStatus);
        setParentSeriesId(parentSeriesId);
        setShowCancel(true);

    }
    return (
        <>
            {/* {JSON.stringify(reload)}--{JSON.stringify(isLoading)} */}
            <ProtectedArea>
                <div className="container">
                    <div className="text-center">
                        <h2 className="fontw-600"> <span className="text-theme1">MY</span> <span className="text-dark">BOOKINGS</span></h2>
                    </div></div>
                <div className="container">
                    {isLoading ? <SiteSpinner /> : null}
                    <div className="row justify-content-center my-booking-table">

                        {!_.isEmpty(bookings) ? <BookingItems bookings={bookings} cancelBookings={cancelHandler} deleteBooking={deleteHandler} {...props} /> : isLoading === false ? _.isEmpty(bookings) ? <RecordNotFound type="secondary" title={"Sorry, there are no record found."} /> : null : null}
                        {apiError ? <RecordNotFound type="danger" title={"Something went wrong, please try again later."} /> : null}
                    </div>
                </div>
                <ConfirmationToast
                    title={toastData.title}
                    description={toastData.description}
                    type={toastData.type}
                    showToast={toastData.showToast}
                    toggleConfirmation={toggleConfirmation}
                />
            </ProtectedArea>
            <Modal show={showCancel} onClose={handleCancelClose}>
                <Modal.Header className="modal-header-confirm">
                Confirmation
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-0"><>Are you sure you want to cancel the booking?</></p>
                </Modal.Body>
                <Modal.Footer className="modal-footer-confirm">
                    <Button type="button" onClick={() => cancelBookings(bookingId, bookingStatus, parentSeriesId)} className="btn btn-lg btn-link fs-6 text-decoration-none col-4 rounded-0 m-0 rounded-0 border-end confirmation-btn-no"><strong>Cancel Selected</strong></Button>
                    <Button type="button" onClick={() => cancelBookingAll(bookingId, bookingStatus, parentSeriesId)} className="btn btn-lg btn-link fs-6 text-decoration-none col-3 rounded-0 m-0 rounded-0 border-end confirmation-btn-no"><strong>Cancel All</strong></Button>
                    <Button type="button" onClick={handleCancelClose} className="btn btn-lg btn-link fs-6 text-decoration-none col-3 m-0 rounded-0 confirmation-btn-yes" data-bs-dismiss="modal">Ignore</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};
export default MyBookings