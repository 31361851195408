import {updateObject} from '../utility'
import * as actionTypes from '../actions/actionTypes';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_CACHE_DATA:
      console.log("STORE_CACHE_DATA",action.cacheData)
      return updateObject(state, action.cacheData);
    case actionTypes.GET_CACHE_DATA:
      return state;
    case actionTypes.CLEAR_CACHE_DATA:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
