import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SiteSpinner from "../../components/UI/Spinner"
import { updateBookingsApi } from '../../services';
import { getLocalData } from '../../services/global-storage';
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const Completion = props => {
    let query = useQuery();
    useEffect(() => {
        (async () => {
            if (query.get("redirect_status") == 'succeeded') {
                await updateBookingsApi({ id: getLocalData('bookingId'), paymentStatus: "Paid", paymentId: query.get("payment_intent"), paymentSecret: query.get("payment_intent_client_secret") })
                props.history.push("/booking-confirmation")
            } else {
                props.history.push("/failed-payment")
            }
        })()
    }, [])
    return <div>
        <SiteSpinner />
    </div>;

}

export default Completion;