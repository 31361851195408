
import React, { useEffect, useState } from 'react';
import ProtectedArea from '../../hoc/protected-area';
import Spinner from 'react-bootstrap/Spinner';
import { Alert, Card, Form, Modal, Toast, ToastContainer } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { FaInfo } from "react-icons/fa"
import * as Yup from "yup";
import { Formik, Field, FieldArray, ErrorMessage, Form as FormFormik } from "formik";
import { getLocalData } from '../../services/global-storage';
import { useParams } from 'react-router-dom';
import ConfirmationToast from '../../components/UI/ConfirmationToast';
import { RiLoader5Line } from "react-icons/ri";
import { PetData } from '../../interfaces';
import { createBookingApi } from '../../services';
import PhoneNumberMask from '../../components/UI/Elements/masked-phone';
import _ from 'lodash';
type SearchParams = {
    sw: string;
}
const EditProfile = (props: any) => {
    const [show, setShow] = useState(false);
    const [toastData, setToastData] = useState<any>({
        title: '',
        desciption: '',
        type: '',
        showToast: false
    })
    const toggleConfirmation = () => {
        setToastData({ ...toastData, showToast: !toastData.showToast });

        if(toastData.showToast) props.history.push("/my-bookings")
    }

    // const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    // const [showError, setShowError] = useState<boolean>(false)
    // const toggleConfirmation = () => setShowConfirmation(!showConfirmation);
    // const toggleError = () => setShowError(!showError);
    const { sw } = useParams<SearchParams>();
    const UserSchema = Yup.object().shape({
        name: Yup.string()
            .required("Name is required"),
        address: Yup.string()
            .required("Street is required"),
        phone_number: Yup.string()
            .required("Phone number is required"),
        city: Yup.string()
            .required("City is required"),
    });
    const composePhoneNumber = (dial_code: string, phone_line_number: string) => {
        return `${dial_code || '+1'}${phone_line_number.replace(/[-()\s]/g, '')}`;
    }
    const formatPhoneNumber = (phone: string) => {
        let newValue = _.replace(phone, '+1', '');
        if (newValue.length > 10) {
            newValue = newValue.slice(0, 10);
        }
        if (newValue.length > 6) {
            newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(3, 6)}-${newValue.slice(6)}`;
        } else if (newValue.length > 3) {
            newValue = `(${newValue.slice(0, 3)}) ${newValue.slice(3)}`;
        }
        return newValue;
    };
    const [success, setSuccess] = useState(false);
    async function updateUser(value: any) {
        try {
            setShow(true);
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {
                'address': value.address,
                'phone_number': composePhoneNumber('+1', value.phone_number),
                'custom:city': value.city,
                'name': value.name,
            });
            setToastData({
                title: 'Profile update',
                description: 'Your profile has been updated successfully.',
                type: 'success',
                showToast: true
            });
            setShow(false);
            if (getLocalData("paymentMethod") == "online" && getLocalData("bookingId")) {
                props.history.push("/payment")
            } else if (getLocalData("paymentMethod") == "offline") {
                if (getLocalData("lastBookingType") == 'Draft') {
                    let bookingData = JSON.parse(getLocalData("lastBookingData"))
                    let allPets: PetData[] | undefined = bookingData.pets;
                    delete bookingData.pets;
                    let bookingId = await createBookingApi(bookingData, allPets)
                    props.history.push(`/booking-confirmation/${bookingId}`)
                } else if (getLocalData("bookingId")) {
                    props.history.push(`/booking-confirmation/${getLocalData("bookingId")}`)
                }
            } else {
                props.history.push("/edit-profile")
            }
        } catch (error) {
            setToastData({
                title: 'Error',
                description: 'Something went wrong! Please try again.',
                type: 'danger',
                showToast: true
            });
        }

    }
    let [initialValue, setInitialValue] = useState({
        name: '',
        address: '',
        phone_number: '',
        city: '',
    });
    let [loggedInUser, setLoggedInUser] = useState<any>();
    async function getUserInfo() {
        const user = await Auth.currentAuthenticatedUser({
            bypassCache: true
        });
        setLoggedInUser(user)
        console.log('attributes:', user, user.attributes.name, user.attributes.address, user.attributes.city, user.attributes.phone_number);
        setInitialValue({
            name: user.attributes.name,
            address: user.attributes.address,
            phone_number: formatPhoneNumber(user.attributes.phone_number),
            city: user.attributes['custom:city'],
        })

    }
    useEffect(() => {
        getUserInfo();
    }, [])
    return (
        <>


            <ProtectedArea>
                <div className="container mt-lg-4 mt-1 mb-90">

                    <div className="row justify-content-center">

                        <div className="col-lg-5 col-md-7 col-sm-10">
                            {sw == 'y' && <Alert key={'warning'} className="" variant={'warning'}><FaInfo />    Please fill your address in order to complete the reservation</Alert>}
                            <div className="card shadow edit-profile-block">

                                <div className="text-center mt-3 edit-profile-title">
                                    <h2> <span className="text-theme1"> EDIT</span> <span className="text-dark" > PROFILE</span></h2>
                                </div>
                                <Card.Body>
                                    <div className="form-group">
                                        <Formik
                                            initialValues={initialValue}
                                            enableReinitialize={true}
                                            validationSchema={UserSchema}
                                            onSubmit={(values, { resetForm }) => updateUser(values)}
                                        >
                                            {({ touched, errors, isSubmitting, values, setFieldValue }) => (

                                                <FormFormik>
                                                    <div className="row">
                                                        <div className="col mb-10">
                                                            <div className="input-group contactme-form-name">
                                                                <a className='text-decoration-none text-danger' href="/change-password">Change Password</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* {loggedInUser ? <div className="row">
                                                            <div className="col mb-10">
                                                                <div className="input-group contactme-form-name">
                                                                    Username: {loggedInUser?.username}
                                                                </div>
                                                            </div>
                                                        </div> : <Spinner animation="border" variant="secondary" size="sm" />} */}
                                                    {loggedInUser ? <div className="row">
                                                        <div className="col mb-10">
                                                            <div className="input-group contactme-form-name">

                                                                Email: {loggedInUser?.attributes.email}&nbsp;<a className='text-decoration-none text-danger' href="/change-email">Change</a>

                                                            </div>
                                                        </div>
                                                    </div> : <Spinner animation="border" variant="secondary" size="sm" />}
                                                    <div className="row">
                                                        <div className="col mb-10">
                                                            <Field
                                                                name={`name`}
                                                            >{({ field, formProps }: any) => (
                                                                <Form.Group controlId={`name`} className="mb-3">
                                                                    <div className="input-group contactme-form-name">
                                                                        <span className="input-group-text"></span>
                                                                        <Form.Control type={'text'} placeholder="Name" value={field.value} onChange={field.onChange} />

                                                                    </div>
                                                                    <ErrorMessage name={`name`} component="div" className={"error"} />
                                                                </Form.Group>
                                                            )}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col mb-10">
                                                            <Field
                                                                name={`phone_number`}
                                                            >{({ field, formProps }: any) => (
                                                                <Form.Group controlId={`phone_number`} className="mb-3">
                                                                    <div className="input-group contactme-form-message">

                                                                        {/* <Form.Control type={'text'} placeholder="Enter your phone number" value={field.value} onChange={field.onChange} /> */}

                                                                        <PhoneNumberMask
                                                                            id="guestPhone"
                                                                            name="guestPhone"
                                                                            type="tel"
                                                                            placeholder="Please enter your phone (required)"
                                                                            field={field}
                                                                            setFieldValue={setFieldValue}
                                                                        />
                                                                    </div>
                                                                    <ErrorMessage name={`phone_number`} component="div" className={"error"} />
                                                                </Form.Group>
                                                            )}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col mb-10">
                                                            <Field
                                                                name={`address`}
                                                            >{({ field, formProps }: any) => (
                                                                <Form.Group controlId={`address`} className="mb-3">
                                                                    <div className="input-group contactme-form-message">
                                                                        {/* <span className="input-group-text"></span> */}
                                                                        <Form.Control type={'text'} placeholder="Enter your street" value={field.value} onChange={field.onChange} />
                                                                    </div>
                                                                    <ErrorMessage name={`address`} component="div" className={"error"} />
                                                                </Form.Group>
                                                            )}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col mb-10">
                                                            <Field
                                                                name={`city`}
                                                            >{({ field, formProps }: any) => (
                                                                <Form.Group controlId={`city`} className="mb-3">
                                                                    <div className="input-group contactme-form-message">
                                                                        {/* <span className="input-group-text"></span> */}
                                                                        <Form.Control type={'text'} placeholder="Enter your city" value={field.value} onChange={field.onChange} />
                                                                    </div>
                                                                    <ErrorMessage name={`city`} component="div" className={"error"} />
                                                                </Form.Group>
                                                            )}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <button className={show ? "btn btn-light" : "btn btn-success"} disabled={show ? true : false}>
                                                            {show ?
                                                                <RiLoader5Line className='mr-2' style={{ color: '#92cff7' }} /> : null
                                                            }
                                                            {show ? <span>Submitting...</span> : <span>Submit/Confirm</span>}</button>
                                                    </div>
                                                </FormFormik>
                                            )}
                                        </Formik>
                                        {success == true &&
                                            <>
                                                <div className="alert alert-success mt-2" role="alert">
                                                    User Update Successfuly !
                                                </div></>
                                        }
                                    </div>
                                </Card.Body>

                            </div>
                        </div>
                    </div>
                </div>
            </ProtectedArea>

            <ConfirmationToast
                title={toastData.title}
                description={toastData.description}
                type={toastData.type}
                showToast={toastData.showToast}
                toggleConfirmation={toggleConfirmation}
            />

            {/* <ToastContainer position="top-end" className="p-3">
                <Toast show={showConfirmation}
                    onClose={toggleConfirmation}
                    bg={'success'}
                >
                    <Toast.Header>
                        <strong className="me-auto">Edit successfully</strong>
                    </Toast.Header>
                    <Toast.Body>Edit User request successfully done !</Toast.Body>
                </Toast>
            </ToastContainer>
            <ToastContainer position="top-end" className="p-3">
                <Toast onClose={toggleError}
                    show={showError}
                    animation={false}
                    bg={'danger'}>
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body className={'text-white'}>Something went wrong! Please try again..</Toast.Body>
                </Toast>
            </ToastContainer> */}
        </>
    );
}
export default EditProfile;

