import { Elements, PaymentElement, PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import Lambda from 'aws-sdk/clients/lambda';
import { getStripeConfigApi, getCreatePaymentIntentApi, getBookingByIdApi } from '../../services';
import CheckoutForm from './checkoutform';
import SiteSpinner from '../../components/UI/Spinner'
import { getLocalData } from '../../services/global-storage';
import moment from 'moment';
import { Alert } from 'react-bootstrap';

export default function Payment() {

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [bookingData, setBookingData] = useState({})

    useEffect(() => {
        getStripeConfigApi().then(({ publishableKey }) => {
            // const { publishableKey } = await r.json();
            // console.log(r)
            setStripePromise(loadStripe(publishableKey));
        });
    }, []);

    useEffect(() => {
        getCreatePaymentIntentApi().then(({ clientSecret }) => {
            // var { clientSecret } = await result.json();
            // console.log(result)
            setClientSecret(clientSecret);
        });
    }, []);

    useEffect(() => {
        async function fetchData() {
            let bookingData = await getBookingByIdApi(getLocalData('bookingId'));
            setBookingData(bookingData.data.getBooking)
        }
        fetchData()
    }, [])


    return (
        <>
            <h1>Make your payment</h1>
            {clientSecret && stripePromise && (
                <div id="payment-form">
                    <p>
                    <Alert>Booking Date {moment(bookingData.bookingDate).format("DD MMM yyyy")} at {bookingData.bookingStartTime}.
                        <br />   Booking Amount <b>${bookingData.bookingAmount}</b> <br />
                    </Alert>
                    </p>
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <CheckoutForm />
                    </Elements>
                </div>
            )}
            {!(clientSecret && stripePromise) && <SiteSpinner />}
        </>
    );
};

