import React, { useEffect } from 'react';
import { createBlogApi } from '../../services';

const Blogs = () => {
    useEffect(()=>{
        createBlogApi()
    },[])
    return (
        <div className='min-height-800'>
            
        </div>
    );
};

export default Blogs;