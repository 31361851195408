import { Button, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Modal, Toast, ToastContainer } from 'react-bootstrap';
import ConfirmationModal from '../../components/UI/ConfirmationModal';
import SiteSpinner from '../../components/UI/Spinner';
import ProtectedArea from '../../hoc/protected-area';
import { BookingData } from '../../interfaces';
import { getBookingsApi, updateBookingsApi, deleteBookingsApi, getBookingsListApi, deleteBookingSeriesApi, updateBookingSeriesApi } from '../../services';
import BookingItems from '../Book/booking-items';
import nxbtn from '../../assets/images/nextbtn.png'
import prbtn from '../../assets/images/prevbtn.png'
import ConfirmationToast from '../../components/UI/ConfirmationToast';
import RecordNotFound from '../../components/UI/RecordNotFound';
function Paginate(props: any) {
    let { isLoading, hasNext, hasPrev, next, prev } = props
    const disabledPrev = !hasPrev || isLoading
    const disabledNext = !hasNext || isLoading
    return (
        <div className="flex justify-between ps-sm-0 pe-4 py-2 mb-2 text-sm bg-white rounded shadow-md lg:py-4 lg:px-8">
            <button
                className='btn text-white mr-2 ps-0 next-btn px-0 btn-disabled'
                disabled={disabledPrev}
                onClick={prev}
            >
                <img className="img-fluid" src={prbtn} alt="" />
            </button>
            <button
                className='btn text-white mr-2 next-btn btn-disabled px-0'
                disabled={disabledNext}
                onClick={next}
            >
                <span className="next-btn"><img className="img-fluid" src={nxbtn} alt="" /></span>

            </button>
        </div>
    )
}
const AllBookings = (props: any) => {
    const [apiError, serApiError] = useState(false);
    const [bookingId, setBookingId] = useState<any>()
    const [parentSeriesId, setParentSeriesId] = useState<any>()
    const [bookingStatus, setBookingStatus] = useState<any>()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [showCancel, setShowCancel] = useState(false);
    const [reload, setReload] = useState(false);
    const handleCancelClose = () => setShowCancel(false);
    const [toastData, setToastData] = useState<any>({
        title: '',
        desciption: '',
        type: '',
        showToast: false
    })
    const toggleConfirmation = () => setToastData({ ...toastData, showToast: !toastData.showToast });
    // const [showConfirmation1, setShowConfirmation1] = useState<boolean>(false)
    // const toggleConfirmation1 = () => setShowConfirmation1(!showConfirmation1);
    const [isLoading, setIsLoading] = useState(true)
    const [nextToken, setNextToken] = useState(undefined)
    const [nextNextToken, setNextNextToken] = useState<any>()
    const [previousTokens, setPreviousTokens] = useState<any[]>([])
    const [bookings, setBookings] = useState<BookingData[]>([])
    const hasNext = !!nextNextToken
    const hasPrev = previousTokens.length
    const limit = 10
    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)
            try {
                setIsLoading(true)
                const response = await getBookingsListApi(limit, nextToken);
                setIsLoading(false)
                setNextNextToken(response.nextToken)
                setBookings(response.items);
                //console.log(response.items);
            } catch (err) {
                serApiError(true);
                console.log(err)
            } finally {
                setIsLoading(false)
            }
        }
        fetch()
    }, [nextToken, reload])

    const next = () => {
        setPreviousTokens((prev: any[]) => [...prev, nextToken])
        setNextToken(nextNextToken)
        setNextNextToken(null)
    }

    const prev = () => {
        setNextToken(previousTokens.pop())
        setPreviousTokens([...previousTokens])
        setNextNextToken(null)
    }

    async function cancelBookings(id: string | undefined, bookingStatus: string, parentSeriesId: string) {
        await updateBookingsApi({id, bookingStatus});
        setToastData({
            title: 'Cancelled successfully',
            description: 'Cancel booking request successfully completed.',
            type: 'success',
            showToast: true
        });
        setShowCancel(false);
        setReload(!reload);
    };
    async function cancelBookingAll(id: string | undefined, bookingStatus: string, parentSeriesId: string) {
        await updateBookingSeriesApi(id, bookingStatus, parentSeriesId);
        // setShowConfirmation(true);
        setShowCancel(false);
        setToastData({
            title: 'Cancelled successfully',
            description: 'Cancel booking series request successfully completed.',
            type: 'success',
            showToast: true
        });
        setReload(!reload)
    };
    async function deleteBooking(id: string) {
        await deleteBookingsApi(id);
        setToastData({
            title: 'Deleted successfully',
            description: 'Delete booking request successfully completed.',
            type: 'success',
            showToast: true
        });
        setShow(false);
        setReload(!reload);
    };
    function deleteHandler(id: string) {
        setBookingId(id);
        setShow(true);
    }
    function cancelHandler(id: string | undefined, bookingStatus: string, parentSeriesId: string) {
        setBookingId(id);
        setParentSeriesId(parentSeriesId);
        setBookingStatus(bookingStatus);
        setShowCancel(true);
    }
    return (
        <>
            <ProtectedArea>
                <div className="container">
                    <div className="text-center">
                        <h2 className="text-center fontw-600 text-theme1">ALL <span className="text-dark"> BOOKINGS</span></h2>
                    </div>
                </div>
                <div className="container mt-md-4 mt-1 mb-4">
                    <div className="row justify-content-center">
                        <Paginate {...{ hasNext, hasPrev, prev, next, isLoading }} />
                        {isLoading ? <SiteSpinner /> : null}
                        {!_.isEmpty(bookings) ? <BookingItems
                            bookings={bookings}
                            deleteBooking={deleteHandler}
                            cancelBookings={cancelHandler}
                            {...props}
                        /> : isLoading === false ? _.isEmpty(bookings) ? <RecordNotFound type="secondary" title={"Sorry, there are no record found."} /> : null : null}
                        {apiError ? <RecordNotFound type="danger" title={"Something went wrong, please try again later."} /> : null}
                    </div>
                </div>
            </ProtectedArea>
            <ConfirmationToast
                title={toastData.title}
                description={toastData.description}
                type={toastData.type}
                showToast={toastData.showToast}
                toggleConfirmation={toggleConfirmation}
            />
            <ConfirmationModal
                title={"Confirmation"}
                show={show}
                noHandler={handleClose}
                yesHandler={() => deleteBooking(bookingId)}
            >
                <>Are you sure you want to delete the booking?</>
            </ConfirmationModal>
            <Modal show={showCancel} onClose={handleCancelClose}>
                <Modal.Header className="modal-header-confirm">
                Confirmation
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-0"><>Are you sure you want to cancel the booking?</></p>
                </Modal.Body>
                <Modal.Footer className="modal-footer-confirm">
                    <Button type="button" onClick={() => cancelBookings(bookingId, bookingStatus, parentSeriesId)} className="btn btn-lg btn-link fs-6 text-decoration-none col-4 rounded-0 m-0 rounded-0 border-end confirmation-btn-no"><strong>Cancel Selected</strong></Button>
                    <Button type="button" onClick={() => cancelBookingAll(bookingId, bookingStatus, parentSeriesId)} className="btn btn-lg btn-link fs-6 text-decoration-none col-3 rounded-0 m-0 rounded-0 border-end confirmation-btn-no"><strong>Cancel All</strong></Button>
                    <Button type="button" onClick={handleCancelClose} className="btn btn-lg btn-link fs-6 text-decoration-none col-3 m-0 rounded-0 confirmation-btn-yes" data-bs-dismiss="modal">Ignore</Button>
                </Modal.Footer>
            </Modal>
        </>

    )

};
export default AllBookings