import React from "react";
import { Box, Email, Item, A, Span, Image } from "react-html-email";
import Config from '../../services/config'
// import logo from '../../assets/images/logo-white.png';
// import thankyou from '../../assets/images/thankyou.jpg';
// const logoUrl = `${window.location.protocol}${window.location.hostname}/${logo}`;
// const thankyouUrl = `${window.location.protocol}${window.location.hostname}/${thankyou}`;
type Props = {
    fullName: string,
    bookingLength: string,
    bookingDate: string;
    bookingStartTime: string,
    serviceType: string,
    petName: string,
    bookingId: string,
};
const containerStyle = {
    backgroundColor: "#F5F8FA",
    width: "100%",
};
const emailHeadCSS = `
  body {
    background-color: #F5F8FA;
  }
`.trim();
const backgroundStyle = {
    WebkitBoxShadow: "6px 6px 40px 3px rgba(140, 152, 164, 0.2)",
    backgroundColor: "#FFF",
    borderRadius: 7,
    boxShadow: "6px 6px 40px 3px rgba(140, 152, 164, 0.2)",
    margin: "0 auto",
    width: "100%",
    padding: "0px 32px",
};
const btnClick = {
    backgroundColor: '#3b6c18',
    color: '#fff',
    padding: ' 6px 20px',
    borderRadius: '5px',
    textDecoration: 'none',
}
const BookingEmail = ({
    fullName,
    bookingLength,
    bookingDate,
    bookingStartTime,
    serviceType,
    petName,
    bookingId,
}: Props) => (
    <Box align="center" style={containerStyle}>
        <Email align="center" headCSS={emailHeadCSS} title="Booking">
            <Item align="center">
                <Box style={backgroundStyle}>
                    <Item style={{ height: 40, backgroundColor: '#5b8a39', textAlign: 'center' }} >
                        <Image alt="Header Image" src='https://petwalker-static.s3.amazonaws.com/logo-white.png' width={200} height={40} />
                    </Item>
                    <Item style={{ height: 25 }} />
                    <Item style={{ paddingLeft: 20 }}>
                        Hello, {fullName},
                    </Item>
                    <Item style={{ height: 10 }} />
                    <Item style={{ paddingLeft: 20 }}>
                        Thank you for your reservation!  You have booked {bookingLength} with Lexi Nation for a {serviceType} {petName} at {bookingStartTime}, on {bookingDate}
                    </Item>
                    <Item style={{ height: 10 }} />
                    <Item style={{ paddingLeft: 20 }} >
                        If you need to modify your reservation, please <A style={btnClick} href={`${Config.baseUrl}/my-bookings`} >click here</A>
                    </Item>
                    <Item style={{ paddingLeft: 20 }} >
                        Best Paws!
                    </Item>
                    <Item style={{ height: 50 }} />
                    <Item> <Image alt="Thanks Image" src='https://petwalker-static.s3.amazonaws.com/thankyou.jpg' /> </Item>
                </Box>
            </Item>
        </Email>
    </Box>
);
export default BookingEmail;