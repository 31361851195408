import { Authenticator, Button, CheckboxField, Image, Loader, PhoneNumberField, TextField, useAuthenticator, useTheme, View, withAuthenticator } from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

import { getLocalData, storeLocalData } from '../../services/global-storage';
const SignInHeader = (props) => <></>
const SignUpHeader = (props) => <></>
const SignUpFooter = (props) => <div style={{ paddingTop: 0, paddingLeft: 15, paddingRight: 15, paddingBottom: 5 }}>If you did not receive a verification email, check your Spam or Junk email folders</div>
const SignInFooter = (props) => <></>
const Header = (props) => <></>
const Login = (props) => {
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: false
        }).then(user => {
            // if (!user.attributes.address) {
            //     props.history.push("/edit-profile")
            // } else if (getLocalData("paymentMethod") == "online" && getLocalData("bookingId")) {
            //     props.history.push("/payment")
            // } else if (getLocalData("paymentMethod") == "offline" && getLocalData("bookingId")) {
            //     props.history.push("/booking-confirmation")
            // } else {
            //     props.history.push("/edit-profile/hello")
            // }
        }).catch(e => {
            if (props.redirectPath)
                storeLocalData('redirectPath', props.redirectPath)

        });
    }, [authStatus])
    const components = {
        Header() {
            const { tokens } = useTheme();
            return (
                <View textAlign="center" padding={tokens.space.large}>

                </View>
            );
        },
        SignIn: {
            Header: SignInHeader,
            Footer: SignInFooter
        },
        SignUp: {
            Header: SignUpHeader,
            Footer: SignUpFooter,
            FormFields() {
                const { validationErrors } = useAuthenticator();

                return (
                    <>
                        {/* Re-use default `Authenticator.SignUp.FormFields` */}
                        <Authenticator.SignUp.FormFields />
                        {/* <TextField
                            name="custom:ownername"
                            placeholder="Enter your name"
                            label="Name"
                            // errorMessage={validationErrors["custom:ownername"]}
                            // hasError={!!validationErrors["custom:ownername"]}
                        /> */}
                        {/* <PhoneNumberField
                            defaultDialCode="+1"
                            name="custom:ownerphone"
                            label="Phone number"
                            // descriptiveText="Please enter your phone number"
                            placeholder="Please enter your phone number"
                        /> */}

                        {/* Append & require Terms & Conditions field to sign up  */}
                        {/* <CheckboxField
                            errorMessage={validationErrors.acknowledgement}
                            hasError={!!validationErrors.acknowledgement}
                            name="acknowledgement"
                            value="yes"
                            label="I agree with the Terms & Conditions"
                        /> */}
                    </>
                );
            }
        },
        Footer() {
            const { toResetPassword } = useAuthenticator();

            return (
                <View textAlign="center">
                    <Button
                        fontWeight="normal"
                        onClick={toResetPassword}
                        size="small"
                        variation="link"
                    >
                        Reset Password
                    </Button>
                </View>
            );
        },
    };
    return (
        <>
        <div className='mb-90'>
        <Authenticator
            authState={"signIn"}
            components={components}
            onStateChange={(authState) => alert(authState)}
            services={{
                // async validateCustomSignUp(formData) {
                //     if (!formData["custom:ownername"]) {
                //         return {
                //             "custom:ownername": 'Please enter name',
                //         };
                //     }
                // }
            }}>
            {({ signOut, user }) => (
                <p>{authStatus === 'configuring' && 'Loading...'}</p>
            )}
        </Authenticator>
        </div>
        </>
    );

}

export default Login;

