import { Button, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ContactData } from '../../interfaces';
import ProtectedArea from '../../hoc/protected-area';
import {  Modal,Toast, ToastContainer } from 'react-bootstrap';
import { getContactQueryApi, updateContactQueryApi, listAllUsersApi, deleteContactApi } from '../../services';
import SiteSpinner from '../../components/UI/Spinner';
import QueriesItem from './queries-item'
import Paging from './paging';
import nxbtn from '../../assets/images/nextbtn.png'
import prbtn from '../../assets/images/prevbtn.png'
import ConfirmationModal from '../../components/UI/ConfirmationModal';
import ConfirmationToast from '../../components/UI/ConfirmationToast';
import RecordNotFound from '../../components/UI/RecordNotFound';
function Paginate(props: any) {
    let { isLoading, hasNext, hasPrev, next, prev } = props
    const disabledPrev = !hasPrev || isLoading
    const disabledNext = !hasNext || isLoading
    return (
        <div className="flex justify-between ps-sm-0 px-3 py-2 mb-2 text-sm bg-white rounded shadow-md lg:py-4 lg:px-8">
            <button
                disabled={disabledPrev}
                onClick={prev}
                className='btn text-white mr-2 px-0 next-btn btn-disabled'
            >
                <img className="img-fluid" src={prbtn} alt="" />
            </button>
            <button
                disabled={disabledNext}
                onClick={next}
                className='btn  text-white mr-2 px-0 next-btn'
            >
                <span className="next-btn"><img className="img-fluid" src={nxbtn} alt="" /></span>

            </button>
        </div>
    )
}
const AllQueries = (props: any) => {
    const [apiError, serApiError] = useState(false);
    const [contactId, setcontactId] = useState<any>()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    // const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [reload, setReload] = useState(false);
    const toggleError = () => setShowError(!showError);
    const [toastData, setToastData] = useState<any>({
        title: '',
        desciption: '',
        type: '',
        showToast: false
    })
    const toggleConfirmation = () => setToastData({...toastData,showToast:!toastData.showToast});
    const [message,setMessage]=useState("");
    const [title,settitle]=useState("");
    const [queries, setQueries] = useState<ContactData[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [nextToken, setNextToken] = useState(undefined)
    const [nextNextToken, setNextNextToken] = useState<any>()
    const [previousTokens, setPreviousTokens] = useState<any[]>([])
    const hasNext = !!nextNextToken
    const hasPrev = previousTokens.length
    const limit =5
    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)
            try {
                setIsLoading(true)
                const response = await getContactQueryApi(limit,nextToken);
                setIsLoading(false)
                setNextNextToken(response.nextToken)
                setQueries(response.items);
            } catch (err) {
                serApiError(true);
                console.log(err)
            } finally {
                setIsLoading(false)
            }
        }
        fetch()
    }, [nextToken,reload])

    const next = () => {
        setPreviousTokens((prev: any[]) => [...prev, nextToken])
        setNextToken(nextNextToken)
        setNextNextToken(null)
    }
    const prev = () => {
        setNextToken(previousTokens.pop())
        setPreviousTokens([...previousTokens])
        setNextNextToken(null)
    }
    async function updateQueryStatus(id: string | undefined, queryStatus: string) {
        await updateContactQueryApi({ id, queryStatus });
        setToastData({
            title: 'Update Status',
            description: 'Update status request was successfully submitted.',
            type: 'success',
            showToast: true
        });
        setReload(true)
    };
    async function deleteQuery(id: string | undefined,) {
        await deleteContactApi(id);
        setToastData({
            title: 'Delete Query',
            description: 'Queries delete request was successfully submitted.',
            type: 'success',
            showToast: true
        });
        setReload(true)
        // setShowConfirmation(true)
        setShow(false);
    };
    function deleteHandler(id: string) {
        setcontactId(id);
        setShow(true);
    }
    return (
        <>
            <ProtectedArea>
                <div className="container">
                    <div className="text-center mt-md-4 mt-1">
                    <h2 className="text-center fontw-600"> <span className="text-theme1">CONTACT</span> <span className="text-dark">US QUERIES</span>   </h2>
                    </div></div>
                <div className="container mb-4">
                    <div className="row justify-content-center user-listing-table">
                        <Paginate {...{ hasNext, hasPrev, prev, next, isLoading }} />
                        {isLoading ? <SiteSpinner /> : null}
                        {!_.isEmpty(queries) ? <QueriesItem queriesList={queries} updateQueryStatus={updateQueryStatus} deleteContact={deleteHandler} /> :isLoading === false ? _.isEmpty(queries) ? <RecordNotFound type="secondary" title={"No Records Found !"} /> : null : null}
                        {apiError ? <RecordNotFound  type="danger" title={"Something went wrong, please try again later."} /> : null}
                    </div>
                </div>
            </ProtectedArea>
            <ConfirmationToast
                title={toastData.title}
                description={toastData.description}
                type={toastData.type}
                showToast={toastData.showToast}
                toggleConfirmation={toggleConfirmation}
            />
            {/* <ToastContainer position="bottom-end" className="p-3">
                <Toast show={showConfirmation}
                    onClose={toggleConfirmation}
                    bg={'success'}
                >
                    <Toast.Header>
                        <strong className="me-auto">{title}</strong>
                    </Toast.Header>
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
            </ToastContainer>
            <ToastContainer position="bottom-end" className="p-3">
                <Toast onClose={toggleError}
                    show={showError}
                    animation={false}
                    bg={'danger'}>
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body className={'text-white'}>Something went wrong! Please try again..</Toast.Body>
                </Toast>
            </ToastContainer> */}
            <ConfirmationModal
                title={"Confirmation"}
                show={show}
                noHandler={handleClose}
                yesHandler={()=>deleteQuery(contactId)}
            >
                <>Are you sure you want to delete the query?</>
            </ConfirmationModal>
        </>
    );
}
export default AllQueries