import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import events from './events';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Appointment, BookingData } from '../../interfaces';
import { getAllAppointmentsApi, getBookingsApi } from '../../services';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import _ from 'lodash';

const localizer = momentLocalizer(moment);

interface Event {
    id: number;
    title: string;
    start: moment.Moment;
    end: moment.Moment;
}

interface Props {
    events: Event[];
}

const EventCalendarPopup = (props: any) => {
    const clickRef = useRef(null)
    const [show, setShow] = useState(false);
    const [bookEvents, setBookEvents] = useState<Appointment[]>();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectedDateRange, setSelectedDateRange] = useState<{
        start: moment.Moment;
        end: moment.Moment;
    } | null>(null);



    useEffect(() => {
        getAllAppointmentsApi().then(r => {
            console.log("[Event Calendar] getAllAppointmentsApi:", r)
            setBookEvents(r)
        })
    }, [])




    return (
        <>
                <div className="row justify-content-center">
                    <Calendar
                        localizer={localizer}
                        events={bookEvents}
                        defaultDate={props.currentDate || new Date()}
                        components={{
                            toolbar: CalendarToolbar,
                            // eventLabel: CustomEventLabel,
                            week: {
                                event: CustomEventLabel,
                            },
                            day: {
                                event: CustomEventLabel,
                            },
                            month: {
                                event: CustomEventLabel,
                            },
                            // eventContainer: CustomEventContainer,
                        }}
                        selectable
                        defaultView={'week'}
                        min={new Date(1972, 0, 1, 8, 0, 0, 0)}
                        max={new Date(1972, 0, 1, 20, 0, 0, 0)}
                    />
                </div>

        </>
    );
};



const CustomEventLabel = ({ event }: { event: any }) => {
    return <>{''}</>;
};

class CalendarToolbar extends Toolbar {

    componentDidMount() {
        const view = this.props.view;
    }

    render() {
        return (
            <div className='rbc-toolbar'>
                <div className="rbc-btn-group">
                    <button type="button" onClick={() => this.navigate('TODAY')}>Today</button>
                    <button type="button" onClick={() => this.navigate('PREV')}> <b>&laquo;</b> </button>
                    <button type="button" onClick={() => this.navigate('NEXT')}> <b>&raquo;</b> </button>
                </div>
                <div className="rbc-toolbar-label">{this.props.label}</div>
                <div className="rbc-btn-group">
                    {/* <button type="button" onClick={this.view.bind(null, 'day')}>Day</button> */}
                    {/* <button type="button" onClick={this.view.bind(null, 'week')}>Week</button> */}
                </div>
            </div>
        );
    }
}

export default EventCalendarPopup;
