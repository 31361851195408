import React from 'react';
import classes from './Spinner.css';
import footer from "../../../assets/images/dogwalk.gif"

const SiteSpinner = () => (
    <div class="spinner-container">
        
        <img className="img-fluid footer-bg paw-print-1" src={footer} alt="" />

    </div>
);

export default SiteSpinner;