import React, { useEffect, useState } from 'react';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import { connect } from 'react-redux';
import _ from 'lodash';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import Axios from 'axios';
import { storeLocalData } from '../../services/global-storage';
import serviceBG from '../../assets/images/services-bg.png';
import serviceImage1 from '../../assets/images/services-img1.jpg';
import serviceImage2 from '../../assets/images/services-img2.jpg';
import factBG from '../../assets/images/fact-bg.png'
import factImg1 from '../../assets/images/fact-img1.png'
import factImg2 from '../../assets/images/fact-img2.png'
import testimonialImg1 from '../../assets/images/testimonial-img1.png'
import testimonialImg2 from '../../assets/images/testimonial-img2.png'
import sampleAThumb from '../../assets/images/sample_a_thumb.jpg'
import sampleBThumb from '../../assets/images/sample_b_thumb.jpg'
import sampleCThumb from '../../assets/images/sample_c_thumb.jpg'
import sampleDThumb from '../../assets/images/sample_d_thumb.jpg'
import sampleEThumb from '../../assets/images/sample_e_thumb.jpg'
import sampleFThumb from '../../assets/images/sample_f_thumb.jpg'
import sampleGThumb from '../../assets/images/sample_g_thumb.jpg'
import sampleHThumb from '../../assets/images/sample_h_thumb.jpg'
import sampleA from '../../assets/images/sample_a.jpg'
import sampleB from '../../assets/images/sample_b.jpg'
import sampleC from '../../assets/images/sample_c.jpg'
import sampleD from '../../assets/images/sample_d.jpg'
import sampleE from '../../assets/images/sample_e.jpg'
import sampleF from '../../assets/images/sample_f.jpg'
import sampleG from '../../assets/images/sample_g.jpg'
import sampleH from '../../assets/images/sample_h.jpg'
import americanRedCertified from '../../assets/images/american-red-certified.png'
import SiteSpinner from '../../components/UI/Spinner'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Book from './book';
import { useLocation, useParams } from 'react-router-dom';
const Home = (props: any) => {
    const location = useLocation();
    const [code, setCode] = useState<string | null>(null)
    const queryParams = new URLSearchParams(location.search);
    useEffect(() => {
        setCode(queryParams.get("code"))
    }, [queryParams])

    return (
        <>
            {!code ? <>
                <section className="home-banner">
                    <div className="container ">
                        <div className="row">
                            <div className="col-xl-8 d-none d-xl-block">
                                <div className="home-banner-aboutus">
                                    <h1 className="text-white"> <span className="text-theme1">Dog</span>&nbsp;walker.
                                        <span className="text-theme1">Cat</span>&nbsp;enthusiast.
                                    </h1>
                                    <p className="text-white">Lexi Nation is a Marin County native who grew up in Ross Valley and attended
                                        Redwood High School. She earned a BA in Interdisciplinary Studies from CIIS where she studied
                                        animal-assisted therapy and psychology. She grew up with two rescue dogs and specializes in anxious
                                        and sensitive animals. She volunteered at a wildlife hospital from 2017 to 2020 and also worked at
                                        a marine mammal hospital from 2018 to 2021. She currently has a black cat named Ezra who she enjoys
                                        spoiling. In addition to animal care, Lexi provides quality
                                        assurance and marketing for a nonprofit web site that
                                        specializes in social science data aggregation.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-4 d-flex align-items-center">
                                <div className="home-banner-bookblock">
                                    <h2 className="text-theme1 text-center text-white">BOOK A SERVICE</h2>

                                    <p className='home-middle-text text-white text-center'>Reserve a date and time for pet care by emailing her directly at <a className="text-white" href="mailto:lexi@lexination.com">lexi@lexination.com</a>.</p> 
                                    
                                    {/* calling 
                                    
                                    or texting <br /> Lexi (<a className="text-white" href="tel:4157179015">415-717-9015</a>) or  */}
                                    
                                    
                                    {/* <Book {...props} /> */}
                                </div>
                            </div>
                        </div>


                    </div>
                </section>
                <section className="">
                    <div className="container">
                        <div className="row justify-content-center">
                            <ScrollAnimation className="col-lg-4 col-md-8 col-sm-5 col-6" animateIn="zoomIn" data-animate="zoomIn" data-duration="3.0s"
                                data-delay="0.2s">
                                <div className="facts-block text-center">
                                    <div className="facts-img">
                                        <img className="img-fluid" src={factBG} alt="" />
                                        <img className="img-fluid" src={factImg1} alt="" />
                                    </div>
                                    <div className="facts-text">
                                        <h2> <span className="fw-bold">16</span> Years of Experience</h2>
                                    </div>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation className="col-lg-4 col-md-8 col-sm-5 col-6" animateIn="zoomIn" data-animate="zoomIn" data-duration="3.0s"
                                data-delay="0.2s">
                                <div className="facts-block text-center">
                                    <div className="facts-img">
                                        <img className="img-fluid" src={factBG} alt="" />
                                        <img className="img-fluid" width={120} src={americanRedCertified} alt="" />
                                    </div>
                                    <div className="facts-text">
                                        <h2>American Red Cross Cat and Dog First Aid Certified</h2>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </section>
                <section className="services">
                    <div className="container">
                        <div className="services-wrap" style={{ width: "100%" }}>
                            <div className="row align-items-center">
                                <h2 className="text-center mb-3 service-title">Services</h2>
                                <ScrollAnimation className="col-sm-4 mb-20" animateIn="fadeInLeft" data-duration="3.0s" data-delay="0.2s">
                                    <div className="services-block text-center">
                                        <div className="services-img">
                                            <img src={serviceImage1} alt="" />
                                        </div>
                                        <div className="services-content text-center mt-3">
                                            <h2>Dog Walking</h2>
                                            <p className="text-muted">Book a 30-minute to 3-hour walk for your dog(s). Walks are available in
                                                your neighborhood, or on local trails and parks.</p>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                                <ScrollAnimation className="col-sm-4 mb-20" animateIn="fadeInLeft" data-animate="fadeInLeft" data-duration="4.0s" data-delay="0.2s">
                                    <div className="services-block text-center">
                                        <div className="services-img">
                                            <img src={serviceImage2} alt="" />
                                        </div>
                                        <div className="services-content text-center mt-3">
                                            <h2>House Visits</h2>
                                            <p className="text-muted">Book a 30-minute to 3-hour house visit for your dog(s) or cat(s) at home.
                                                Check-ins include outside time for dogs, treats, and lots of attention while you’re away.</p>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn="fadeInRight" className=" col-sm-4 mb-20" data-duration="3.0s" data-delay="0.2s" >
                                    <img src={serviceBG} alt="" className='animate bannerimmg' data-animate="fadeInRight" />
                                </ScrollAnimation>
                            </div>
                        </div>

                    </div>

                </section>
                <section className="testimonial-wrap">
                    <div className="container">
                        <h2 className="slider-titile text-center">Testimonials</h2>
                        <OwlCarousel className='owl-carousel-testimonial owl-theme owl-carousel'
                            loop
                            margin={10}
                            autoplay={false}
                            nav={false}
                            items={2}>
                            <div className="item">
                                <div className="homepage-testimoinal-block">
                                    <div className="row align-items-center">
                                        <div className="col-xl-6 col-lg-4">
                                            <div className="testimonial-img">
                                                <img src={testimonialImg1} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-8">
                                            <div className="testimonial-content">
                                                <p className="">&#10077;</p>
                                                <p>I've trusted Lexi with my dog care needs for years and have found her to be reliable, wonderful with my senior dog, and a real joy to work with. The dog now prefers her over me and I can't even be mad.</p>
                                                <p className="text-end">&#10078;</p>
                                                <p className="">Rob and Zooey</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="item animate">
                                <div className="homepage-testimoinal-block">
                                    <div className="row align-items-center">
                                        <div className="col-xl-6 col-lg-4 col-sm-4">
                                            <div className="testimonial-img">
                                                <img src={testimonialImg2} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-8 col-sm-8">
                                            <div className="testimonial-content">
                                                <p className="">&#10077;</p>
                                                <p>There’s nobody else I’d trust with my pets. Lexi’s rare, innate ability to connect with animals mean my pets have spent the last decade getting excited for me to go out of town. I couldn’t ask for anything else!</p>
                                                <p className="text-end">&#10078;</p>
                                                <p className="">Holden and Bidy</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </OwlCarousel>
                    </div>
                </section>
                <section className="gallery-wrap">
                    <div className="container">
                        <div className="gallery-title">
                            <h2 className="text-theme1 text-center">Gallery</h2>
                        </div>
                        <div className="row">
                            <ScrollAnimation className="col-lg-3 col-sm-6  gallery-col-one" animateIn="fadeIn">
                                <ul className="gallery list-unstyled">
                                    <li className="mb-20">
                                        <a className="animate" href={sampleA} data-animate="fadeIn" data-duration="3.0s"
                                            data-delay="0.2s"><img src={sampleAThumb} alt="Image" /></a>
                                    </li>
                                    <li>
                                        <a className="animate" href={sampleB} data-animate="fadeIn" data-duration="3.0s"
                                            data-delay="0.3s"><img src={sampleBThumb} alt="Image" /></a>
                                    </li>
                                </ul>
                            </ScrollAnimation>
                            <ScrollAnimation className="col-lg-3 col-sm-6 gallery-col-two" animateIn="fadeIn">
                                <ul className="gallery list-unstyled">
                                    <li className="mb-20">
                                        <a className="animate" href={sampleC} data-animate="fadeIn" data-duration="3.0s"
                                            data-delay="0.4s"><img src={sampleCThumb} alt="Image" /></a>
                                    </li>
                                    <li>
                                        <a className="animate" href={sampleD} data-animate="fadeIn" data-duration="3.0s"
                                            data-delay="0.5s"><img src={sampleDThumb} alt="Image" /></a>
                                    </li>
                                </ul>
                            </ScrollAnimation>
                            <ScrollAnimation className="col-lg-3 col-sm-6 gallery-col-three" animateIn="fadeIn">
                                <ul className="gallery list-unstyled">
                                    <li className="mb-20">
                                        <a className="animate" href={sampleE} data-animate="fadeIn" data-duration="3.0s"
                                            data-delay="0.6s"><img src={sampleEThumb} alt="Image" /></a>
                                    </li>
                                    <li>
                                        <a className="animate" href={sampleF} data-animate="fadeIn" data-duration="3.0s"
                                            data-delay="0.7s"><img src={sampleFThumb} alt="Image" /></a>
                                    </li>
                                </ul>
                            </ScrollAnimation>
                            <ScrollAnimation className="col-lg-3 col-sm-6" animateIn="fadeIn">
                                <ul className="gallery list-unstyled">
                                    <li className="mb-20">
                                        <a className="animate" href={sampleG} data-animate="fadeIn" data-duration="3.0s"
                                            data-delay="0.8s"><img src={sampleGThumb} alt="Image" /></a>
                                    </li>
                                    <li>
                                        <a className="animate" href={sampleH} data-animate="fadeIn" data-duration="3.0s"
                                            data-delay="0.9s"><img src={sampleHThumb} alt="Image" /></a>
                                    </li>
                                </ul>
                            </ScrollAnimation>
                        </div>
                    </div>
                </section>
            </> : <SiteSpinner />}
        </>
    );

}

export default withErrorHandler(Home, Axios);