import { Button, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ContactData, Unavailablity } from '../../interfaces';
import ProtectedArea from '../../hoc/protected-area';
import { getContactQueryApi, updateContactQueryApi, listAllUsersApi, getUnavailablityApi, deleteUnavailabityApi } from '../../services';
import SiteSpinner from '../../components/UI/Spinner';
import RecordNotFound from '../../components/UI/RecordNotFound';
const AllUnavailability = (props: any) => {
    const [apiError, serApiError] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [unavailbility, setUnavailability] = useState<Unavailablity[]>([])
    useEffect(() => {
        fetchUnavailability()
    }, [])
    async function fetchUnavailability() {
        setIsLoading(true)
        const response = await getUnavailablityApi();
        setIsLoading(false)
        setUnavailability(response)
    };
    async function deleteBooking(id: string | undefined) {
        await deleteUnavailabityApi(id);
        const response = await getUnavailablityApi();
        setUnavailability(response)
    };
    return (
        <>
            <ProtectedArea>
                <div className="container min-height-900">
                    <div>
                        <h2>Unavailable queries</h2>
                    </div>
                    <div className="row justify-content-center">
                        {isLoading ? <SiteSpinner /> : null}
                        {!_.isEmpty(unavailbility) ?
                            <Table
                                caption=""
                                highlightOnHover={false}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell as="th">Start</TableCell>
                                        <TableCell as="th">End</TableCell>
                                        <TableCell as="th">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {unavailbility.map((o: any, i: number) => <TableRow key={i}>
                                        <TableCell>{o.start}</TableCell>
                                        <TableCell>{o.end}</TableCell>
                                        <TableCell>
                                            <Button
                                                fontWeight="normal"
                                                size="small"
                                                variation="link"
                                                onClick={() => { deleteBooking(o.id) }}
                                            >Delete</Button>
                                        </TableCell>
                                    </TableRow>)}
                                </TableBody>
                            </Table>
                            :  isLoading === false ? _.isEmpty(unavailbility) ? <RecordNotFound type="secondary" title={"Sorry, there are no record found."} /> : null : null}
                        {apiError ? <RecordNotFound type="danger" title={"Something went wrong, please try again later."} /> : null}
                    </div>
                </div>
            </ProtectedArea>
        </>
    );
}
export default AllUnavailability