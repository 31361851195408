import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmationModal = (props: any) => {
    return (
        <Modal show={props.show} onClose={props.handleClose}>
            <Modal.Header className="modal-header-confirm">
                {props.title}
            </Modal.Header>
            <Modal.Body>
                <p className="mb-0">{props.children}</p>
            </Modal.Body>
            <Modal.Footer className="modal-footer-confirm">
                <Button type="button" onClick={() => props.yesHandler()} className="btn btn-lg btn-link fs-6 text-decoration-none col-6 rounded-0 m-0 rounded-0 border-end confirmation-btn-yes"><strong>Yes</strong></Button>
                <Button type="button" onClick={() => props.noHandler()} className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 confirmation-btn-no" data-bs-dismiss="modal">No</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;