import { Button, Link, Table, TableBody, TableCell, TableHead, TableRow } from '@aws-amplify/ui-react';
import _, { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { getUserAttribute } from '../../store/utility';
import RecordNotFound from '../../components/UI/RecordNotFound';
const UserList = (props: any) => {
    return (
        <>
            {!isEmpty(props.queriesList) ?
                <Table
                    className='all-user-table table-responsive ps-sm-0'
                    caption=""
                    highlightOnHover={false}>
                    <TableHead>
                        <TableRow>
                            <TableCell as="th">Name</TableCell>
                            <TableCell as="th">Contact Number</TableCell>
                            <TableCell as="th">Email</TableCell>
                            <TableCell as="th">Verify Contact</TableCell>
                            <TableCell as="th">Verify Email</TableCell>
                            <TableCell as="th">Action</TableCell>
                            {/* <TableCell as="th">Status</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.queriesList.map((o: any, i: number) =>
                            <TableRow key={i}>
                                <TableCell className='d-none-md width-50-sm font-weight-bold'>Name</TableCell>
                                <TableCell as="td">{getUserAttribute(o.Attributes, "name")}</TableCell>
                                <TableCell className='d-none-md width-50-sm font-weight-bold'>Contact Nuber</TableCell>
                                <TableCell as="td">{getUserAttribute(o.Attributes, "phone_number")}</TableCell>
                                <TableCell className='d-none-md width-50-sm font-weight-bold'>Email</TableCell>
                                <TableCell as="td">{getUserAttribute(o.Attributes, "email")}</TableCell>
                                <TableCell className='d-none-md width-50-sm font-weight-bold'>Verify Contact</TableCell>
                                <TableCell as="td">{getUserAttribute(o.Attributes, "phone_number_verified")}</TableCell>
                                <TableCell className='d-none-md width-50-sm font-weight-bold'>Verify Email</TableCell>
                                <TableCell as="td">{getUserAttribute(o.Attributes, "email_verified") == true ? "Yes" : "No"}</TableCell>
                                {/* <TableCell as="td">{o.Enabled == true ?"Activate ":"Inactivate"}</TableCell> */}
                                <TableCell as="td">
                                    <Button
                                        fontWeight="normal"
                                        size="small"
                                        variation="link"
                                        onClick={() => props.handlerStatus(o.Username,o.Enabled == true?"/disableUser":"/enableUser")}
                                    >{o.Enabled == true ? "Inactivate" : "Activate"}</Button>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                : <RecordNotFound title={"Sorry, there are no record found."} />}

        </>

    )
};
export default UserList