/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStripeConfig = /* GraphQL */ `
  query GetStripeConfig($path: String, $httpMethod: String) {
    getStripeConfig(path: $path, httpMethod: $httpMethod)
  }
`;
export const getCreatePaymentIntent = /* GraphQL */ `
  query GetCreatePaymentIntent(
    $path: String
    $amount: String
    $httpMethod: String
  ) {
    getCreatePaymentIntent(
      path: $path
      amount: $amount
      httpMethod: $httpMethod
    )
  }
`;
export const getBookingDraft = /* GraphQL */ `
  query GetBookingDraft($id: ID!) {
    getBookingDraft(id: $id) {
      id
      bookingID
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      repeat
      guestEmail
      guestPhone
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBookingDrafts = /* GraphQL */ `
  query ListBookingDrafts(
    $filter: ModelBookingDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookingDrafts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bookingID
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        repeat
        guestEmail
        guestPhone
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPetDraft = /* GraphQL */ `
  query GetPetDraft($id: ID!) {
    getPetDraft(id: $id) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        repeat
        guestEmail
        guestPhone
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      bookingDraftPetsId
    }
  }
`;
export const listPetDrafts = /* GraphQL */ `
  query ListPetDrafts(
    $filter: ModelPetDraftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPetDrafts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        petType
        petName
        petNeeds
        createdAt
        updatedAt
        bookingDraftPetsId
      }
      nextToken
    }
  }
`;
export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      bookingID
      parentSeriesId
      bookingDate
      bookingStartTime
      bookingEndTime
      bookingLength
      serviceType
      reserveUser
      userId
      repeat
      guestEmail
      guestPhone
      guestCity
      guestAddress
      bookingStatus
      paymentStatus
      paymentMethod
      paymentId
      paymentSecret
      repeatDuration
      bookingAmount
      pets {
        nextToken
      }
      ownerDetail {
        id
        name
        email
        phone
        address
        city
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bookingID
        parentSeriesId
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        userId
        repeat
        guestEmail
        guestPhone
        guestCity
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPet = /* GraphQL */ `
  query GetPet($id: ID!) {
    getPet(id: $id) {
      id
      petType
      petName
      petNeeds
      booking {
        id
        bookingID
        parentSeriesId
        bookingDate
        bookingStartTime
        bookingEndTime
        bookingLength
        serviceType
        reserveUser
        userId
        repeat
        guestEmail
        guestPhone
        guestCity
        guestAddress
        bookingStatus
        paymentStatus
        paymentMethod
        paymentId
        paymentSecret
        repeatDuration
        bookingAmount
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      bookingPetsId
      owner
    }
  }
`;
export const listPets = /* GraphQL */ `
  query ListPets(
    $filter: ModelPetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        petType
        petName
        petNeeds
        createdAt
        updatedAt
        bookingPetsId
        owner
      }
      nextToken
    }
  }
`;
export const getContactQuery = /* GraphQL */ `
  query GetContactQuery($id: ID!) {
    getContactQuery(id: $id) {
      id
      name
      email
      phone
      subject
      message
      queryStatus
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listContactQueries = /* GraphQL */ `
  query ListContactQueries(
    $filter: ModelContactQueryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContactQueries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        subject
        message
        queryStatus
        userId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUnavailablity = /* GraphQL */ `
  query GetUnavailablity($id: ID!) {
    getUnavailablity(id: $id) {
      id
      title
      start
      end
      type
      createdAt
      updatedAt
    }
  }
`;
export const listUnavailablities = /* GraphQL */ `
  query ListUnavailablities(
    $filter: ModelUnavailablityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnavailablities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        start
        end
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
