import React, { useEffect, useState } from 'react';
import { listAllUsersApi, updateUserApi } from '../../services';
import { ContactData } from '../../interfaces';
import ProtectedArea from '../../hoc/protected-area';
import SiteSpinner from '../../components/UI/Spinner';
import UsersList from './users-list';
import _ from 'lodash';
import RecordNotFound from '../../components/UI/RecordNotFound';
import ConfirmationToast from '../../components/UI/ConfirmationToast';
import nxbtn from '../../assets/images/nextbtn.png'
import prbtn from '../../assets/images/prevbtn.png'
const Users = () => {
    const [apiError, serApiError] = useState(false);
    const [reload, setReload] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [nextToken, setNextToken] = useState(undefined)
    const [nextNextToken, setNextNextToken] = useState<any>()
    const [previousTokens, setPreviousTokens] = useState<any[]>([])
    const [usersLists, setUserLists] = useState<ContactData[]>([])
    const [toastData, setToastData] = useState<any>({
        title: '',
        desciption: '',
        type: '',
        showToast: false
    })
    const toggleConfirmation = () => setToastData({ ...toastData, showToast: !toastData.showToast });
    const hasNext = !!nextNextToken
    const hasPrev = previousTokens.length
    function Paginate(props: any) {
        let { isLoading, hasNext, hasPrev, next, prev } = props
        const disabledPrev = !hasPrev || isLoading
        const disabledNext = !hasNext || isLoading
        return (
            <div className="flex justify-between ps-sm-0 pe-4 py-2 mb-2 text-sm bg-white rounded shadow-md lg:py-4 lg:px-8">
                <button
                    className='btn text-white mr-2 ps-0 next-btn px-0 btn-disabled'
                    disabled={disabledPrev}
                    onClick={prev}
                >
                    <img className="img-fluid" src={prbtn} alt="" />
                </button>
                <button
                    className='btn text-white mr-2 next-btn btn-disabled px-0'
                    disabled={disabledNext}
                    onClick={next}
                >
                    <span className="next-btn"><img className="img-fluid" src={nxbtn} alt="" /></span>
    
                </button>
            </div>
        )
    }
    const next = () => {
        setPreviousTokens((prev: any[]) => [...prev, nextToken])
        setNextToken(nextNextToken)
        setNextNextToken(null)
    }

    const prev = () => {
        setNextToken(previousTokens.pop())
        setPreviousTokens([...previousTokens])
        setNextNextToken(null)
    }
    const limit=5;
    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true)
            try {
                setIsLoading(true)
                const users = await listAllUsersApi(limit);
                setIsLoading(false)
                setNextNextToken(users.nextToken)
                setUserLists(users.Users);
                console.log(users);
            } catch (err) {
                serApiError(true);
                console.log(err)
            } finally {
                setIsLoading(false)
            }
        }
        fetch()
    }, [nextToken, reload])
    async function updateQueryStatus(id: any | undefined, pathValue: string) {
        await updateUserApi(id, pathValue);
        setToastData({
            title: 'Update Status',
            description: 'Update status request was successfully submitted.',
            type: 'success',
            showToast: true
        });
        setReload(true)
    };
    return (
        <ProtectedArea>
            <div className="container ">
                <div className="text-center mt-lg-4 mb-lg-3">
                    <h2 className="text-center fontw-600 mb-0"> <span className="text-theme1">USER</span>  <span className="text-dark">LISTING</span> </h2>
                </div></div>
            <div className="container mb-4">
                <div className="row justify-content-center user-listing-block">
                     <Paginate {...{ hasNext, hasPrev, prev, next, isLoading }} />
                    {isLoading ? <SiteSpinner /> : null}
                    {!_.isEmpty(usersLists) ? <UsersList queriesList={usersLists} handlerStatus={updateQueryStatus} /> : isLoading === false ? _.isEmpty(usersLists) ? <RecordNotFound type="secondary" title={"Sorry, there are no record found."} /> : null : null}
                    {apiError ? <RecordNotFound type="danger" title={"Something went wrong, please try again later."} /> : null}
                </div>
            </div>
            <ConfirmationToast
                title={toastData.title}
                description={toastData.description}
                type={toastData.type}
                showToast={toastData.showToast}
                toggleConfirmation={toggleConfirmation}
            />
        </ProtectedArea>
    );
};

export default Users;