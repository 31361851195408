import { useAuthenticator } from '@aws-amplify/ui-react';
import React, { Component } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SiteSpinner from '../components/UI/Spinner';
import Login from '../containers/Member/login';
const ProtectedArea = (props) => {
    const { authStatus } = useAuthenticator(context => [context.authStatus]);
    const location = useLocation();

    return (<div className=''>
        {authStatus === 'configuring' && <SiteSpinner />}
        {authStatus !== 'authenticated' ? <Login redirectPath={location.pathname}/> : <>{props.children}</>}
    </div>
    )

}
export default ProtectedArea;
