import React, { Component } from 'react';
import classes from './Layout.css';
import responsiveclasses from './Responsive.css';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import { Col, Container, Row } from 'react-bootstrap';
import Footer from '../../components/Navigation/Footer';
import { Loader } from '@aws-amplify/ui-react';

class Layout extends React.Component<{ isAuthenticated: boolean, children: any }, { showSideDrawer: boolean }> {
    state: any = {
        showSideDrawer: false
    }

    sideDrawerClosedHandler = () => {
        this.setState({ showSideDrawer: false });
    }

    sideDrawerToggleHandler = () => {
        this.setState((prevState) => {
            return { showSideDrawer: !prevState.showSideDrawer };
        });
    }

    render() {
        return (
            <>
                <Toolbar
                    {...this.props}
                    isAuth={this.props?.isAuthenticated}
                    drawerToggleClicked={this.sideDrawerToggleHandler} />

                <Container fluid style={{ width: '100%', height: '100%', marginTop: 20, marginLeft: 0, paddingLeft: 0, marginRight: 0, paddingRight: 0, minHeight: 500 }}>
                    {(this.props.children) ? this.props.children : <Loader />}
                </Container>
                <Footer />

            </>
        )
    }
}


export default Layout;
