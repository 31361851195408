import React, { useEffect, useState } from 'react';
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import serviceImage1 from '../../assets/images/services-img1.jpg'
import serviceImage2 from '../../assets/images/services-img2.jpg'
import bannerRates from '../../assets/images/banner-rates.png'
import watchImage from '../../assets/images/watch.png'
import dogShape1 from '../../assets/images/dog-shape1.png'
import dogShape2 from '../../assets/images/dog-shape2.png'
import dogShape3 from '../../assets/images/dog-shape3.png'
import dogShape4 from '../../assets/images/dog-shape4.png'

import houseShape1 from '../../assets/images/house-shape1.png'
import houseShape2 from '../../assets/images/house-shape2.png'
import houseShape3 from '../../assets/images/house-shape3.png'
import houseShape4 from '../../assets/images/house-shape4.png'

const Rates = (props: any) => {
    return (
        <div>
            <section className="contactme-banner">
                <div className="container">
                    {/* <div className="contactme-banner-titile text-center">
                        <button type="button" className="btn-appointment-serivce-page" onClick={()=>props.history.push("/")}>BOOK AN APPOINTMENT</button>
                    </div> */}
                </div>
            </section>
            <section className="service-rate-wrap">
                <div className="container">
                    <div className="row justify-content-center">
                        <ScrollAnimation className="col-xl-6 col-lg-6 mb-lg-0 mb-20" animateIn="zoomIn" data-animate="zoomIn" data-duration="3.0s" data-delay="0.2s">
                            <div className="cat-walking">
                                <div className="dog-walk-title text-center mb-3">
                                    <img src={serviceImage1} alt="" /> <span>Dog Walking</span>
                                </div>
                                <div className="dog-walk-table table-responsive">
                                    <table className="table text-center">
                                        <thead>
                                            <tr>
                                                <th className="bg-white"></th>
                                                <th className="mw-100">
                                                    <div className="dog-minute-block dog-minute-bg1 text-center pt-2 py-1">
                                                        <img className="img-fluid d-block m-auto" src={watchImage} alt="" />
                                                        <label className="dog-minute">30 Min</label>
                                                    </div>
                                                </th>
                                                <th className="mw-100">
                                                    <div className="dog-minute-block dog-minute-bg2 text-center pt-2 py-1">
                                                        <img className="img-fluid d-block m-auto" src={watchImage} alt="" />
                                                        <label className="dog-minute">1 Hour</label>
                                                    </div>
                                                </th>
                                                <th className="mw-100">
                                                    <div className="dog-minute-block dog-minute-bg3 text-center pt-2 py-1">
                                                        <img className="img-fluid d-block m-auto" src={watchImage} alt="" />
                                                        <label className="dog-minute">2 Hour</label>
                                                    </div>
                                                </th>
                                                <th className="mw-100">
                                                    <div className="dog-minute-block dog-minute-bg4 text-center pt-2 py-1">
                                                        <img className="img-fluid d-block m-auto" src={watchImage} alt="" />
                                                        <label className="dog-minute">3 Hour</label>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="mw-100 dog-number"> <span className="pt-18">1 Dog</span></td>
                                                <td className="green-light pt-0 pe-0 ps-0">
                                                    <div className="dog-price-shape1">
                                                        <img className="img-fluid d-block m-auto" src={dogShape1} alt="" />
                                                        <label className="dog-price">$30</label>
                                                    </div>
                                                </td>
                                                <td className="pt-0 pe-0 ps-0">
                                                    <div className="dog-price-shape1">
                                                        <img className="img-fluid d-block m-auto" src={dogShape2} alt="" />
                                                        <label className="dog-price">$35</label>
                                                    </div>
                                                </td>
                                                <td className="green-light pt-0 pe-0 ps-0">
                                                    <div className="dog-price-shape1">
                                                        <img className="img-fluid d-block m-auto" src={dogShape3} alt="" />
                                                        <label className="dog-price">$60</label>
                                                    </div>
                                                </td>
                                                <td className="pt-0 pe-0 ps-0">
                                                    <div className="dog-price-shape1 ">
                                                        <img className="img-fluid d-block m-auto" src={dogShape4} alt="" />
                                                        <label className="dog-price">$80</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="mw-100 dog-number">2 Dogs</td>
                                                <td className="green-light-1 dog-price">$40</td>
                                                <td className="dog-price">$60</td>
                                                <td className="green-light-1 dog-price">$80</td>
                                                <td className="dog-price">$100</td>
                                            </tr>
                                            <tr>
                                                <td className="mw-100 dog-number">3 Dogs</td>
                                                <td className="green-light dog-price">$60</td>
                                                <td className="dog-price">$80</td>
                                                <td className="green-light dog-price">$100</td>
                                                <td className="dog-price">$120</td>
                                            </tr>
                                            {/* <tr><td colSpan={5} className="mw-100 dog-number"><img src={bannerRates} alt="" width={400} /></td></tr> */}
                                        </tbody>
                                    </table>


                                </div>
                                <div style={{textAlign:'center'}}>
                                <img src={bannerRates} className="discountImg" alt=""/>
                                </div>
                            </div>
                            {/* <div style={{ marginLeft: 100, marginTop: 10 }}>
                                <img src={bannerRates} alt="" width={400} />
                            </div> */}
                        </ScrollAnimation>
                        <ScrollAnimation className="col-xl-6 col-lg-6" animateIn="zoomIn" data-animate="zoomIn" data-duration="3.0s" data-delay="0.3s">
                            <div className="cat-walking catWalkingMarginTop">
                                <div className="dog-walk-title text-center mb-3">
                                    <img src={serviceImage2} alt="" /><span>House Visit</span>
                                </div>
                                <div className="house-visit-table table-responsive">
                                    <table className="table text-center">
                                        <thead>
                                            <tr>
                                                <th className="bg-white"></th>
                                                <th className="mw-100">
                                                    <div className="dog-minute-block house-minute-bg1 text-center pt-2 py-1">
                                                        <img className="img-fluid d-block m-auto" src={watchImage} alt="" />
                                                        <label className="dog-minute">30 Min</label>
                                                    </div>
                                                </th>
                                                <th className="mw-100">
                                                    <div className="dog-minute-block house-minute-bg2 text-center pt-2 py-1">
                                                        <img className="img-fluid d-block m-auto" src={watchImage} alt="" />
                                                        <label className="dog-minute">1 Hour</label>
                                                    </div>
                                                </th>
                                                <th className="mw-100">
                                                    <div className="dog-minute-block house-minute-bg3 text-center pt-2 py-1">
                                                        <img className="img-fluid d-block m-auto" src={watchImage} alt="" />
                                                        <label className="dog-minute">2 Hour</label>
                                                    </div>
                                                </th>
                                                <th className="mw-100">
                                                    <div className="dog-minute-block house-minute-bg4 text-center pt-2 py-1">
                                                        <img className="img-fluid d-block m-auto" src={watchImage} alt="" />
                                                        <label className="dog-minute">3 Hour</label>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="dog-number"> <span className="pt-18">1 Dog</span> </td>
                                                <td className="green-light pt-0 pe-0 ps-0">
                                                    <div className="house-visit-shape1">
                                                        <img className="img-fluid d-block m-auto" src={houseShape1} alt="" />
                                                        <label className="dog-price">$30</label>
                                                    </div>
                                                </td>
                                                <td className="pt-0 pe-0 ps-0">
                                                    <div className="house-visit-shape1">
                                                        <img className="img-fluid d-block m-auto" src={houseShape2} alt="" />
                                                        <label className="dog-price">$35</label>
                                                    </div>
                                                </td>
                                                <td className="green-light pt-0 pe-0 ps-0">
                                                    <div className="house-visit-shape1">
                                                        <img className="img-fluid d-block m-auto" src={houseShape3} alt="" />
                                                        <label className="dog-price">$60</label>
                                                    </div>
                                                </td>
                                                <td className="pt-0 pe-0 ps-0">
                                                    <div className="house-visit-shape1">
                                                        <img className="img-fluid d-block m-auto" src={houseShape4} alt="" />
                                                        <label className="dog-price">$80</label>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="mw-100 dog-number">2 Dogs</td>
                                                <td className="green-light-1 dog-price">$40</td>
                                                <td className="dog-price">$60</td>
                                                <td className="green-light-1 dog-price">$80</td>
                                                <td className="dog-price">$100</td>
                                            </tr>
                                            <tr>
                                                <td className="mw-100 dog-number">3 Dogs</td>
                                                <td className="green-light dog-price">$60</td>
                                                <td className="dog-price">$80</td>
                                                <td className="green-light dog-price">$100</td>
                                                <td className="dog-price">$120</td>
                                            </tr>
                                            <tr>
                                                <td className="mw-100 dog-number">1 Cat</td>
                                                <td className="green-light-1 dog-price">$30</td>
                                                <td className="dog-price">$35</td>
                                                <td className="green-light-1 dog-price">$45</td>
                                                <td className="dog-price">$60</td>
                                            </tr>
                                            <tr>
                                                <td className="mw-100 dog-number">2 Cats</td>
                                                <td className="green-light-1 dog-price">$30</td>
                                                <td className="dog-price">$35</td>
                                                <td className="green-light-1 dog-price">$45</td>
                                                <td className="dog-price">$60</td>
                                            </tr>
                                            <tr>
                                                <td className="mw-100 dog-number">3 Cats</td>
                                                <td className="green-light dog-price">$35</td>
                                                <td className="dog-price">$40</td>
                                                <td className="green-light dog-price">$55</td>
                                                <td className="dog-price">$65</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </section>
        </div>
    );

}

export default Rates;

